import {
  createJacket,
  getJacketData,
  selectVehicle,
  updateJacket,
} from "@services/jacket";
import has from "lodash/has";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useUpdateJacketMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateJacket, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["jacket-details", id]);
      queryClient.invalidateQueries(["jacket-data", id]);
    },
  });
};

export const useJacketData = (jacketId, options = {}) =>
  useQuery(["jacket-data", jacketId], () => getJacketData(jacketId), {
    ...options,
    enabled: has(options, "enabled")
      ? options.enabled && Boolean(jacketId)
      : Boolean(jacketId),
  });

export const useCreateJacketMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(createJacket, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["jacket-details", id]);
    },
  });
};

export const useSelectVehicleMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(selectVehicle, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["jacket-details", id]);
    },
  });
};
