/**
 *
 * @param {*} budget
 * @param {*} downPayment
 * @param {*} loanTerms
 * @returns
 */
export const calculateEMI = (
  budget,
  downPayment,
  interestRate,
  loanTerms,
  residualValue = 0
) => {
  const totalLoanAmount = budget - residualValue - downPayment;
  const interest = interestRate / 12 / 100;
  let n = totalLoanAmount * interest * Math.pow(1 + interest, loanTerms);
  let d = Math.pow(1 + interest, loanTerms) - 1;
  let emi = Math.round(n / d);
  emi = emi < 0 ? 0 : emi;
  return {
    emi,
  };
};

export function calculateMonthlyLoanPayment(
  salePrice,
  fees,
  sellRate,
  tradeInNet,
  additionalDownPayment,
  term,
  taxRate
) {
  let cost = salePrice - tradeInNet;
  let taxes = cost * taxRate;
  cost = cost + fees + taxes - additionalDownPayment;
  let interestOverYear = sellRate / 12;
  let x = Math.pow(1 + interestOverYear, term);
  let baseMonthly = (cost * x * interestOverYear) / (x - 1);
  let monthlyWithTax = baseMonthly;
  return monthlyWithTax;
}

export function calculateMonthlyLeasePayment(
  salePrice,
  residual,
  fees,
  tradeInNet,
  additionalDownPayment,
  term,
  moneyFactor,
  taxRate
) {
  let cost = salePrice + fees - (tradeInNet + additionalDownPayment);
  let depreciation = cost - residual;
  let baseMonthly = depreciation / term;
  let rent = cost * moneyFactor;
  let preTaxMonthly = baseMonthly + rent;
  let taxes = preTaxMonthly * taxRate;
  let monthlyWithTax = preTaxMonthly + taxes;
  return monthlyWithTax;
}
