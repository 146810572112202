import CarImage from "@components/CarImage";
import classNames from "classnames";
import { snakeCase } from "lodash";
import React from "react";
import { MdArrowBackIos, MdCheckCircle, MdCircle } from "react-icons/md";
import styles from "./styles.module.scss";

const StepItem = ({
  isEnabled,
  isActive,
  imageVin,
  showIndicator = true,
  handleClick,
  isCompleted,
  title,
  subtitle,
}) => {
  const hasImage = Boolean(imageVin);
  return (
    <div onClick={isEnabled ? handleClick : undefined}>
      <li
        className={classNames(
          "px-3",
          "d-flex",
          "mt-2",
          styles.listGroupItem,
          "align-items-center",
          "justify-content-between",
          {
            [styles.activePage]: isActive,
            [styles.bgProduct]: hasImage,
          }
        )}
        id={`${snakeCase(title)}`}
      >
        <div className="align-self-center">
          <MdArrowBackIos
            size={25}
            color={!isEnabled ? "#BCBCBB" : "#035f77"}
          />
        </div>
        <div className="flex-grow-1 px-3 d-flex car-avatar">
          {imageVin && (
            <CarImage vin={imageVin} size="small" className={styles.carImage} />
          )}
          <div
            className={classNames(
              "px-2",
              "d-flex",
              "flex-column",
              "align-self-center",
              { "text-muted": !isEnabled }
            )}
          >
            <h6>{title}</h6>
            <p className="text-subtitle text-muted text-sm">{subtitle}</p>
          </div>
        </div>
        {showIndicator &&
          (isCompleted ? (
            <div className="d-flex justify-content-center align-items-center align-self-center">
              <MdCheckCircle color="#05AA06" size={22} />
            </div>
          ) : isActive ? (
            <div className="d-flex justify-content-center align-items-center align-self-center">
              <MdCircle color="#035f77" size={20} />
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center align-self-center">
              <div className={styles.circle} />
            </div>
          ))}
      </li>
    </div>
  );
};

export default StepItem;
