import {
  IconFB,
  IconInstagram,
  IconLinkedin,
  IconPinterest,
  IconTwitter,
  LogoFooter,
} from "@assets/Images";
import CompanyName from "@components/CompanyName";
import { COMPANY_CONTACT_NUMBER, COMPANY_EMAIL } from "@constants/";
import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "./styles.module.scss";

const Footer = () => (
  <footer className={styles.main}>
    <Container fluid>
      <Row>
        <div className={classNames("py-5", styles.footerWrapper)}>
          <div
            className={classNames(
              "d-lg-flex",
              "text-lg-start",
              "text-center",
              styles.footerTop
            )}
          >
            <div
              className={classNames(
                styles.footLogoImg,
                "text-center",
                "text-lg-start"
              )}
            >
              <img src={LogoFooter} alt="Logo" />

              <p className="text-white my-3">
                <CompanyName bold={false} /> was founded in 2018 and has now
                moved into the automotive market as a disruptive technology.
                This revolutionary platform will change the way people buy and
                sell cars forever.
              </p>
              <p>Learn more</p>
            </div>
            <div className="text-center text-lg-start">
              <h5>
                About <CompanyName bold={false} />
              </h5>
              <ul>
                <li className="my-2">About Us</li>

                <li>Contact </li>
              </ul>
            </div>

            <div className="text-center text-lg-start">
              <h5 className="mb-2">Contact Info</h5>
              <p>
                <CompanyName showTM={false} showInc bold={false} />
              </p>
              <p>417 5th Avenue</p>
              <p>Indialantic, Fl. 32903</p>
              <p className="mt-5">{COMPANY_CONTACT_NUMBER}</p>
              <p>{COMPANY_EMAIL}</p>
            </div>
          </div>
          <h1 className="title text-center mt-5 text-white">
            This Bid's For You.
          </h1>
        </div>

        <Row sm={12} className={classNames(styles.tagline, "p-3 m-0")}>
          <Col lg={6} sm={12} className="text-center text-lg-start">
            <p className={styles.footCopyright}>
              Copyright &copy; {dayjs().get("year")}{" "}
              <CompanyName showTM={false} showInc bold={false} /> All Rights
              Reserved.
            </p>
          </Col>
          <Col lg={6} sm={12} className="text-center text-lg-end">
            <div className={styles.socialLinks}>
              <img alt="fb" src={IconFB} />
              <img alt="twitter" src={IconTwitter} />
              <img alt="linkedin" src={IconLinkedin} />
              <img alt="pinterest" src={IconPinterest} />
              <img alt="instagram" src={IconInstagram} />
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  </footer>
);

export default Footer;
