import Space from "@components/Space";
import { useToggle } from "@hooks/useToggle";
import React from "react";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { toggleAuthModal } from "src/redux/Actions/Auth";
import BuyerForgotModal from "./BuyerAuthModal/BuyerForgotModal";
import BuyerSignInModal from "./BuyerAuthModal/BuyerSignInModal";
import BuyerSignupModal from "./BuyerAuthModal/BuyerSignupModal";
import Sidebar from "./Sidebar";
import styles from "./styles.module.scss";

const NotLoggedIn = () => {
  const [isOpen, togglePopUp] = useToggle();
  const dispatch = useDispatch();

  return (
    <div className="user-area flex-column">
      <Space align="center" classes={styles.auth}>
        <Link
          to={"/"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(toggleAuthModal("signup"));
          }}
          className={styles.link}
          id="signUpModelOpen_"
        >
          Sign Up
        </Link>
        |
        <Link
          to={"/"}
          onClick={(e) => {
            e.preventDefault();
            dispatch(toggleAuthModal("signin"));
          }}
          className={styles.link}
          id="signInModelOpen_"
        >
          Login
        </Link>
        <div onClick={togglePopUp} id="menuItems_">
          <RiBarChartHorizontalLine color="black" />
        </div>
      </Space>
      {isOpen && (
        <div className={styles.sidebar}>
          <Sidebar toggle={togglePopUp} />
        </div>
      )}

      <BuyerSignupModal />
      <BuyerSignInModal />
      <BuyerForgotModal />
    </div>
  );
};

export default NotLoggedIn;
