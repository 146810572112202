import dayjs from "dayjs";
import { get } from "lodash";

/**
 *
 * @param {*} lastRequestedDate
 * @returns
 */
export const isCreditScoreExpired = (lastRequestedDate) => {
  const fetchedDate = dayjs(lastRequestedDate);
  const today = dayjs();
  return (
    fetchedDate.get("month") + fetchedDate.get("year") <
    today.get("month") + today.get("year")
  );
};

/**
 *
 * @param {*} user
 * @returns
 */
export const getCreditScore = (user) => {
  let creditScore;
  const requested = get(user, "payload.prequalify.requested");
  if (user && !isCreditScoreExpired(requested)) {
    creditScore = get(user, "payload.prequalify.score");
  }

  return creditScore;
};
