import { changeBidderPassword, login, refreshToken } from "@services/auth";
import Cookies from "js-cookie";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import useAuth from "./useAuth";

export const useLogin = () => {
  return useMutation(login);
};

export const useBidderChangePassword = () => {
  return useMutation(changeBidderPassword);
};

export const useRefreshToken = () => {
  const history = useHistory();
  const { isAuthenticated, user, logout } = useAuth();

  return useQuery(["refresh-token"], () => refreshToken(), {
    enabled: isAuthenticated && Boolean(user),
    retry: false,
    refetchIntervalInBackground: true,
    refetchOnReconnect: true,
    refetchInterval: 60 * 1000,
    onSuccess: ({ token }) => {
      Cookies.set("loginToken", token);
    },
    onError: () => {
      logout(() => {
        history.push(`/`);
      });
    },
  });
};
