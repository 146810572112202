import {
  AuctionThumb1,
  AuctionThumb2,
  AuctionThumb3,
  AuctionThumb4,
  AuctionThumb5,
} from "@assets/Images";
import React from "react";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.css";
// Import Swiper styles
import "swiper/swiper.scss";
import "./AuctionCarousel.scss";

// install Swiper modules
SwiperCore.use([Pagination]);

const dataArr = [
  {
    time: "4:59:00",
    imgSrc: AuctionThumb1,
    price: "$59,900.00",
    title: "2021 Chevrolet Corvette",
  },
  {
    time: "4:58:11",
    imgSrc: AuctionThumb2,
    price: "$29,600.00",
    title: "2021 Kia Sportage",
  },
  {
    time: "4:56:57",
    imgSrc: AuctionThumb3,
    price: "$28,500.00",
    title: "2021 Ford Bronko",
  },
  {
    time: "4:13:60",
    imgSrc: AuctionThumb4,
    price: "$24,820.00",
    title: "2021 Ford Ranger",
  },
  {
    time: "3:56:40",
    imgSrc: AuctionThumb5,
    price: "$39,900.00",
    title: "2021 Kia stinger",
  },
];

const CarouselThumb = ({ time = "", imgSrc = "", price = "", title = "" }) => (
  <div className="carousel-thumb">
    <h4>{time}</h4>
    <figure>
      <img src={imgSrc} className="img-fluid" alt="currently in live auction" />
      <figcaption>
        {price} <small>{title}</small>
      </figcaption>
    </figure>
  </div>
);

const AuctionCarousel = () => {
  const options = {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 50,
    },
  };

  return (
    <Swiper
      spaceBetween={30}
      slidesPerView={1}
      breakpoints={options}
      loop={true}
      pagination={{
        clickable: true,
      }}
    >
      {dataArr.map((slide) => (
        <SwiperSlide key={slide.title}>
          <CarouselThumb
            time={slide.time}
            imgSrc={slide.imgSrc}
            price={slide.price}
            title={slide.title}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default AuctionCarousel;
