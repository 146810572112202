import { CAR_TYPE } from "@constants/";
import { DATA_TYPES } from "@constants/";
import { callAPI } from "@utils/Api";
import { get } from "lodash";
import {
  CONFIG_DATA_INITIAL,
  CONFIG_REQUEST_FAILED,
  CONFIG_REQUEST_STARTED,
  CONFIG_REQUEST_SUCCEEDED,
  MAKE_INVENTORY_INITIAL,
  SET_INVENTORY_CAR_LIST,
} from "./ActionTypes";
import { makeFilterInitial } from "./FilterActions";
import { getYearCars, setAllStepsData } from "./StepsActions";

const url = `vehicle/configuration/create`;

const makeInventoryList = (data, dispatch) => {
  const { search, selected } = data;
  const usedCars_list =
    selected &&
    selected.length &&
    selected.filter((item) => item.stock_type.toLowerCase() === CAR_TYPE.USED);

  const newCars_list =
    selected &&
    selected.length &&
    selected.filter((item) => item.stock_type.toLowerCase() === CAR_TYPE.NEW);

  const currentYear = new Date().getFullYear();

  const dispatchInventory = (type) =>
    dispatch({
      type: SET_INVENTORY_CAR_LIST,
      payload: {
        invCars: type === CAR_TYPE.NEW ? newCars_list : usedCars_list,
        carsType: type,
      },
    });

  if (search.model_year >= currentYear.toString()) {
    newCars_list && newCars_list.length
      ? dispatchInventory(CAR_TYPE.NEW)
      : dispatchInventory(CAR_TYPE.USED);
  } else {
    usedCars_list && usedCars_list.length
      ? dispatchInventory(CAR_TYPE.USED)
      : dispatchInventory(CAR_TYPE.NEW);
  }
};

export const retrieveConfig =
  (configId, index = "east") =>
  async (dispatch, getState) => {
    const { StepsData } = getState();
    const setStepsData = ({ model_year, make, model, series, style, uvc }) => {
      const newStepsData = {
        make: make[0].text,
        model: model[0].text,
        series: series[0].text || "blank",
        year: model_year[0].text,
        style: { uvc: uvc[0].text, name: style[0].text },
      };
      dispatch(setAllStepsData(newStepsData));
      dispatch(getYearCars(model_year[0].text));
    };

    const data = {
      id: configId,
      type: DATA_TYPES.VEHICLE_CONFIGURATION,
    };

    try {
      const response = await callAPI("data/retrieve", "POST", data);
      dispatch({
        type: CONFIG_DATA_INITIAL,
      });
      dispatch({
        type: MAKE_INVENTORY_INITIAL,
      });

      dispatch({
        type: CONFIG_REQUEST_SUCCEEDED,
        payload: response,
      });

      makeInventoryList(response._source.payload, dispatch);
      if (!StepsData.year) {
        setStepsData(response._source.payload.keys);
      }
    } catch (error) {
      dispatch({
        type: CONFIG_REQUEST_FAILED,
        payload: error,
      });
    }
  };

export const createConfiguration =
  (radius, zip, goToPage) => async (dispatch, getState) => {
    const { StepsData, YmmCarsData } = getState();
    const modelImage = get(YmmCarsData, "stepBgDyn", null);
    const selectedData = get(StepsData, "selectedData", {});

    if (selectedData?.style?.uvc) {
      dispatch({
        type: CONFIG_REQUEST_STARTED,
      });

      const body = {
        zip: zip,
        uvc: selectedData?.style?.uvc,
        radius: radius,
        model_year: selectedData.year,
        make: selectedData.make,
        model: selectedData.model,
        series: selectedData.series,
        style: selectedData?.style?.name,
        in_state: false,
        all_trims: false,
        dealer_stats: true,
        vehicle_model_image: modelImage,
        token: window.ENV.REACT_APP_ACTUAL_IMAGES_TOKEN,
        platform: "dealersbid",
      };

      try {
        const response = await callAPI(url, "POST", body);
        dispatch(retrieveConfig(response.id));
        dispatch(makeFilterInitial());
        goToPage(response.id);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("No uvc found..");
    }
  };

export const createCofigByStyle = (uvc) => async (dispatch, getState) => {
  const { Configuration, YmmCarsData } = getState();
  const { stepBgDyn: modelImage } = YmmCarsData;
  const configData = Configuration.data;
  const payload = configData && configData._source.payload;
  const search = payload && payload.search;

  dispatch({
    type: CONFIG_REQUEST_STARTED,
  });

  const body = {
    uvc: uvc,
    in_state: false,
    zip: search.zip,
    all_trims: false,
    dealer_stats: false,
    radius: search.radius,
    vehicle_model_image: modelImage,
    token: window.ENV.REACT_APP_ACTUAL_IMAGES_TOKEN,
  };

  try {
    const response = await callAPI(url, "POST", body);

    dispatch(retrieveConfig(response.id));
  } catch (error) {
    console.log(error);
  }
};

export const configInitial = () => ({
  type: CONFIG_DATA_INITIAL,
});
