import {
  BUYER_AUCTION_ADD_BID,
  BUYER_AUCTION_BID_CLEAR,
} from "../Actions/ActionTypes";

const initialState = {
  data: [],
};

const BuyerAuctionReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUYER_AUCTION_ADD_BID:
      return {
        data: [...state.data, action.payload],
      };
    case BUYER_AUCTION_BID_CLEAR:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default BuyerAuctionReducer;
