import {
  CAR_ACTUAL_DATA_VIN_FAILED,
  CAR_ACTUAL_DATA_VIN_STARTED,
  CAR_ACTUAL_DATA_VIN_SUCCEEDED,
} from "../Actions/ActionTypes";

const initialState = {
  data: null,
  isLoading: true,
  error: null,
};

const CarActualDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CAR_ACTUAL_DATA_VIN_STARTED:
      return {
        ...state,
        isLoading: true,
      };

    case CAR_ACTUAL_DATA_VIN_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case CAR_ACTUAL_DATA_VIN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default CarActualDataReducer;
