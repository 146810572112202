import Modal from "@components/Modal";
import { toggleAuthModal } from "@redux/Actions/Auth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../styles.module.scss";
import RenderForm from "./RenderForm";

const BuyerSignInModal = () => {
  const isShow = useSelector(
    (state) => state.authModalReducer.name === "signin"
  );
  const dispatch = useDispatch();
  const onClose = () => dispatch(toggleAuthModal(""));

  return (
    <Modal
      centered
      scrollable
      isOpen={isShow}
      toggle={onClose}
      className={styles.modalBox}
      title={
        <div className="d-block">
          <h5 className={styles.title}>Welcome Back!</h5>
          <p className={styles.subHeader}>Please login to your account</p>
        </div>
      }
      filledHeader
      footer={null}
    >
      <RenderForm />
    </Modal>
  );
};
export default BuyerSignInModal;
