import useAuth from "@hooks/useAuth";
import { useToggle } from "@hooks/useToggle";
import { Avatar } from "@material-ui/core";
import { getInitials } from "@utils/generic";
import { get } from "lodash";
import React, { useState } from "react";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap";
import Sidebar from "./Sidebar";
import styles from "./styles.module.scss";

const LoggedIn = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout, type } = useAuth();
  const [isSidebarOpen, toggleSidebar] = useToggle();

  const onLogout = () =>
    logout(() => {
      if (type !== "buyer") {
        return history.push(`/dealer/login`);
      }
      history.push(`/`);
    });

  const onHover = () => setIsOpen(true);

  const onHoverLeave = () => setIsOpen(false);

  const role = get(user, "role");

  return (
    <div className="user-area">
      <figure className="d-flex align-items-center gap-4">
        <div
          onMouseLeave={onHoverLeave}
          onMouseOver={onHover}
          id="profileAvatar_"
        >
          <Avatar
            className="me-2"
            sx={{ height: 30, width: 30, fontSize: 14, background: "#035f77" }}
            id="Popover1"
            src={user?.payload?.profileImage}
          >
            {user.name && getInitials(user.name)}
          </Avatar>

          <Popover
            placement="bottom"
            className="dropdownMenuProfile"
            isOpen={isOpen}
            target="Popover1"
            trigger="focus"
          >
            <PopoverBody className="p-0">
              <ul className="profile-option">
                {type === "buyer" && (
                  <li id="dashboard_">
                    <Link to="/dashboard">Dashboard</Link>
                  </li>
                )}
                {(role === "manager" || type === "buyer") && (
                  <li id="myProfile_">
                    <Link to="/profile/registration">My Profile</Link>
                  </li>
                )}
                {role === "manager" && (
                  <>
                    <li>
                      <Link to="#">Manage Account</Link>
                    </li>
                    <li>
                      <Link to="#">Settings & Privacy</Link>
                    </li>
                  </>
                )}
                <li onClick={onLogout} id="signOut_">
                  <Link to="#">Sign out</Link>
                </li>
              </ul>
            </PopoverBody>
          </Popover>
        </div>
      </figure>
      <RiBarChartHorizontalLine onClick={toggleSidebar} />
      {isSidebarOpen && (
        <div className={styles.sidebar}>
          <Sidebar toggle={toggleSidebar} />
        </div>
      )}
    </div>
  );
};

export default LoggedIn;
