import Modal from "@components/Modal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAuthModal } from "src/redux/Actions/Auth";
import styles from "../styles.module.scss";
import RenderForm from "./RenderForm";

const BuyerSignInModal = () => {
  const isShow = useSelector(
    (state) => state.authModalReducer.name === "forgotPassword"
  );
  const dispatch = useDispatch();
  const onClose = () => dispatch(toggleAuthModal(""));
  return (
    <Modal
      centered
      scrollable
      isOpen={isShow}
      toggle={onClose}
      className={styles.modalBox}
      footer={null}
      filledHeader
      title={
        <div className="d-block">
          <h5 className={styles.title}>Forgot Password!</h5>
          <p className={styles.subHeader}>
            Enter your email and we send a password reset link
          </p>
        </div>
      }
    >
      <RenderForm />
    </Modal>
  );
};
export default BuyerSignInModal;
