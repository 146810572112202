import MuiAlert from "@material-ui/core/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import React, { forwardRef, useState } from "react";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomSnackBar = ({ type, text }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={type && type}
        sx={{ width: "100%" }}
      >
        {text && text}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackBar;
