import { callAPI } from "@utils/Api";

export const getIncentivePayment = (data) =>
  callAPI(
    "incentive/payment",
    "POST",
    data,
    undefined,
    window.ENV.REACT_APP_FINANCIAL_API
  );

export const getVendorPayments = (data) =>
  callAPI("vendors/payment", "POST", data);

export const getVendorPaymentQuote = (data) =>
  callAPI("vendors/payment/quote", "POST", data);

export const getVendorVehicleDetails = (data) =>
  callAPI("vendors/vehicle", "POST", data);
