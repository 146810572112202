import { SearchIcon } from "@assets/Images/SvgIcons";
import CustomSelect from "@components/CustomSelect";
import classNames from "classnames";
import { omit, snakeCase } from "lodash";
import React from "react";
import { BsCheck, BsX } from "react-icons/bs";
import { FormGroup, Input, InputGroup, InputGroupText } from "reactstrap";
import styles from "./styles.module.scss";

const ThemeInput = ({
  error,
  label,
  help,
  boldLabel,
  showRequiredMark,
  onSearch,
  groupProps = {},
  showIcons = false,
  isValidInput,
  prefix,
  variant = "secondary",
  ...props
}) => {
  return (
    <>
      <FormGroup {...groupProps}>
        <InputGroup className={styles.group}>
          <span
            className={classNames(styles.inputLabel, {
              [styles.bold]: boldLabel,
              [styles.primary]: variant === "primary",
              [styles.secondary]: variant === "secondary",
            })}
          >
            {label}
            {showRequiredMark && <sup className={styles.astric}>*</sup>}
          </span>

          {props.type === "select" ? (
            <CustomSelect {...props} className="form-select" />
          ) : (
            <div className="w-100 position-relative">
              {prefix && (
                <InputGroupText className={styles.inputPrefix}>
                  {prefix}
                </InputGroupText>
              )}
              <Input
                id={`${snakeCase(props.name)}`}
                className={classNames(
                  `form-control-text shadow-md w-100`,
                  styles.inputField,
                  {
                    [styles.hasPrefix]: Boolean(prefix),
                  }
                )}
                {...omit(props, "className")}
                invalid={Boolean(error)}
              />
            </div>
          )}
          {error && (
            <div className={classNames("text-danger", styles.error)}>
              {error}
            </div>
          )}
          {props.type === "search" && (
            <span className={styles.search} onClick={onSearch}>
              {SearchIcon}
            </span>
          )}
          {showIcons && (
            <>
              {isValidInput ? (
                <span className={styles.tick}>
                  <BsCheck className={styles.tickMarkSymbol} />
                </span>
              ) : (
                <span className={classNames(styles.tick, styles.invalid)}>
                  <BsX className={styles.tickMarkSymbol} />
                </span>
              )}
            </>
          )}
        </InputGroup>
      </FormGroup>
      {help && <small className={styles.info}>{help}</small>}
    </>
  );
};

export default ThemeInput;
