import { vehicleNotFound } from "@assets/Images";
import { callAPI } from "@utils/Api";
import {
  YMM_BACKUP_DYN_IMAGE_BG,
  YMM_DATA_INITIAL,
  YMM_REQUEST_FAILED,
  YMM_REQUEST_STARTED,
  YMM_REQUEST_SUCCEEDED,
  YMM_STOCK_IMG_REQUEST_FAILED,
  YMM_STOCK_IMG_REQUEST_STARTED,
  YMM_STOCK_IMG_REQUEST_SUCCEEDED,
} from "./ActionTypes";

export const getStepsBgByYmm = () => async (dispatch, getState) => {
  const { YmmCarsData, StepsData } = getState();
  const { series } = StepsData.selectedData;
  const ymmData = YmmCarsData && YmmCarsData.data;

  if (ymmData) {
    try {
      let ab_image_id = "";
      if (series) {
        const modelTrims = ymmData.vehicles.find(
          (car) => car.ux.trim.toLowerCase() === series.toLowerCase()
        );

        ab_image_id = modelTrims?.ux?.ab_image_id || "";
      }

      const filterYmmData = ymmData.images.filter(
        (item) =>
          item.ux.background === "transparent" && item.ux.size === "2400"
      );

      const { id } = filterYmmData && filterYmmData[0];

      dispatch({
        type: YMM_STOCK_IMG_REQUEST_STARTED,
      });

      const url = `vehicle/stock/images`;

      const body = {
        ab_image_id,
        image_id: id,
        token: window.ENV.REACT_APP_ACTUAL_IMAGES_TOKEN,
      };

      const { data } = await callAPI(url, "POST", body);

      dispatch({
        type: YMM_STOCK_IMG_REQUEST_SUCCEEDED,
        payload: data.urls[0]?.url || vehicleNotFound,
      });
    } catch (error) {
      dispatch({
        payload: vehicleNotFound,
        type: YMM_STOCK_IMG_REQUEST_SUCCEEDED,
      });
      dispatch({
        payload: "Unable to find the car.",
        type: YMM_STOCK_IMG_REQUEST_FAILED,
      });
    }
  }
};

//make the year make model to initial state
export const ymmInitial = () => ({
  type: YMM_DATA_INITIAL,
});

const getCars = async (dispatch, year, make, model) => {
  try {
    dispatch({
      type: YMM_REQUEST_STARTED,
    });
    const url = `vehicle/stock/year_make_model`;
    const body = {
      year: year,
      make: make,
      model: model,
      token: window.ENV.REACT_APP_ACTUAL_IMAGES_TOKEN,
    };

    const data = await callAPI(url, "POST", body);

    // If data successfully posted then
    dispatch({
      payload: data,
      type: YMM_REQUEST_SUCCEEDED,
    });

    // data && dispatch(getStepsBgByYmm());
  } catch (error) {
    dispatch({
      payload: error,
      type: YMM_REQUEST_FAILED,
    });
  }
};

export const getCarsByYmm = () => (dispatch, getState) => {
  // Get steps data from current state
  const {
    selectedData: { year, make, model, series },
  } = getState().StepsData;

  if (window.location.pathname === "/buy-a-vehicle") {
    if (series) {
      dispatch(ymmInitial());
    } else {
      getCars(dispatch, year, make, model);
    }
  } else {
    getCars(dispatch, year, make, model);
  }
};

export const backupDynCarImg = () => (dispatch, getState) =>
  dispatch({
    type: YMM_BACKUP_DYN_IMAGE_BG,
    payload: getState().YmmCarsData.stepBgDyn,
  });
