import { callAPI } from "@utils/Api";
import { get } from "lodash";

/**
 *
 * @param {*} incentive_requests
 * @returns
 */
export const getIncentives = async (incentive_requests) => {
  const data = await callAPI(
    "incentive/list",
    "POST",
    {
      incentive_requests,
    },
    undefined,
    window.ENV.REACT_APP_FINANCIAL_API
  );

  return get(data, "[0].incentives", []);
};

export const getVendorIncentives = async (data) => {
  return callAPI("vendors/rebates", "POST", data);
};

export const getDBRates = async () =>
  callAPI(
    "incentive/db_rates",
    "POST",
    {},
    undefined,
    window.ENV.REACT_APP_FINANCIAL_API
  );

export const getFinancialProductsList = (data) =>
  callAPI(
    "product/quote",
    "POST",
    data,
    undefined,
    window.ENV.REACT_APP_FINANCIAL_API
  );

export const getDeliveryQuote = (data) =>
  callAPI("delivery/quote", "POST", data);
