import {
  getVehicleConfiguration,
  updateSelectedVehicles,
  updateVehicleConfigurationAttributes,
} from "@services/vehicle-config";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useVehicleConfiguration = (id) =>
  useQuery(["vehicle-configurations", id], () => getVehicleConfiguration(id), {
    enabled: Boolean(id),
  });

export const useUpdateVehicleConfigurationAttributes = (reset = true) => {
  const queryClient = useQueryClient();

  return useMutation(updateVehicleConfigurationAttributes, {
    onSuccess: (_, { id }) => {
      if (reset) queryClient.invalidateQueries(["vehicle-configurations", id]);
    },
  });
};

export const useUpdateSelectedVehicles = () => {
  const queryClient = useQueryClient();

  return useMutation(updateSelectedVehicles, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["vehicle-configurations", id]);
    },
  });
};
