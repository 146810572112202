import CookieModal from "@components/CookiePopUp/CookieModal";
import Loader from "@components/Loader";
import { createTheme, ThemeProvider } from "@material-ui/core";
import axios from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./App.scss";
import store from "./redux/store";
import RootRouterOutlet from "./Routes/RootRouter";

dayjs.extend(utc);

const client = new QueryClient({
  defaultOptions: {
    mutations: {
      throwOnError: true,
    },
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  },
});
const theme = createTheme({
  palette: {
    primary: {
      main: "#00bcd4",
    },
    secondary: {
      main: "#035f77",
    },
  },
});
const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const getConfig = async () => {
      try {
        window.ENV = (await axios.get("/config.json")).data;
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

    getConfig();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <p>Config not provided</p>;
  }

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <QueryClientProvider client={client}>
          <RootRouterOutlet />
          <CookieModal />
        </QueryClientProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
