import { first, snakeCase } from "lodash";
import { uniqueArr } from "./methods";

/**
 *
 * @param {*} name
 * @returns
 */
export const getInitials = (name = "", length = 2) => {
  let initials = name.split(" ");

  if (initials.length > 1) {
    initials = initials
      .map((i) => i[0])
      .join("")
      .substring(0, length);
  } else {
    initials = name.substring(0, length);
  }

  return initials.toUpperCase();
};
/**
 *
 * @param {*} form
 * @returns
 */
export const getSnakeCaseVersionOfForm = (form) => {
  const reqObject = {};
  Object.keys(form).forEach((key) => {
    if (typeof form[key] === "object") {
      reqObject[snakeCase(key)] = getSnakeCaseVersionOfForm(form[key]);
    } else {
      reqObject[snakeCase(key)] = form[key];
    }
  });

  return reqObject;
};

/**
 * zipcode validator
 * @param {*} zip
 * @returns
 */
export const isValidUSZip = (zip) => {
  return /^\d{5}(-\d{4})?$/.test(zip);
};

export const getNumber = (_str) => {
  var arr = _str.split("");
  var out = [];
  for (var cnt = 0; cnt < arr.length; cnt++) {
    if (isNaN(arr[cnt]) === false) {
      out.push(arr[cnt]);
    }
  }
  return Number(out.join(""));
};

// Client side VIN validation
export const validateVin = (vin) => {
  return validate(vin);
};

function validate(vin) {
  if (vin.length !== 17) return false;
  return getCheckDigit(vin) === vin[8];
}

const getCheckDigit = (vin) => {
  var map = "0123456789X";
  var weights = "8765432X098765432";
  var sum = 0;
  for (var i = 0; i < 17; ++i)
    sum += transliterate(vin[i]) * map.indexOf(weights[i]);
  return map[sum % 11];
};

const transliterate = (c) => {
  return "0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ".indexOf(c) % 10;
};

//get min max value for filter
export const getMinMax = (dataArr, filterName) => {
  const typeArr = dataArr?.map((item) => item[filterName]);
  const minVal = typeArr && Math.min(...typeArr);
  const maxVal = typeArr && Math.max(...typeArr);
  return [minVal, maxVal];
};

//client timezone
export const getCurrentTimeZone = () => {
  const { 1: tz } = new Date().toString().match(/\((.+)\)/);

  if (tz.includes(" ")) {
    return tz
      .split(" ")
      .map(([first]) => first)
      .join("");
  } else {
    return tz;
  }
};
/**
 *
 * @param {*} vehicles
 * @returns
 */
export const createVinPattern = (vehicles) => {
  const vinPattern = vehicles.map((vehicle) => {
    let vin = vehicle?.vin?.slice(0, 11);
    vin = vin.substring(0, 8) + "_" + vin.substring(9);
    return vin;
  });

  return uniqueArr([], vinPattern);
};
/**
 * Get average msrp for given vehicles
 * @param {*} selectedVehicles
 * @returns
 */
export const getAveragePrice = (selectedVehicles = []) => {
  if (!selectedVehicles.length) return 0;

  return first(selectedVehicles)?.msrp;
};
/**
 *
 * @param {*} ms
 * @returns
 */
export const msToHHMMSS = (ms, hideHours = false) => {
  // 1- Convert to seconds:
  let seconds = Math.abs(ms / 1000);
  // 2- Extract hours:
  const hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
  seconds = seconds % 3600; // seconds remaining after extracting hours
  // 3- Extract minutes:
  const minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
  // 4- Keep only seconds not extracted to minutes:
  seconds = seconds % 60;
  let hourStr = hideHours
    ? ""
    : `${hours
        .toString()
        .padStart(hours < 10 ? 2 : hours.toString().length, 0)}:`;
  return ms < 0
    ? "00:00:00"
    : `${hourStr}${minutes.toString().padStart(2, 0)}:${seconds
        .toFixed(0)
        .padStart(2, 0)}`;
};
