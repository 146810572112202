import Cookies from "js-cookie";
import { useContext } from "react";
import { UserDetailsContext } from "../Routes/AuthenticatedRoute";

const useAuth = () => {
  const { user, getUserDetails, setUser } = useContext(UserDetailsContext);
  return {
    isAuthenticated: Boolean(Cookies.get("loginToken")),
    user,
    logout: (callback) => {
      Cookies.remove("loginToken");
      Cookies.remove("tokenExpiration");
      setUser({});
      callback();
    },
    type: user.type,
    refechUser: getUserDetails,
  };
};

export default useAuth;
