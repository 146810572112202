import axios from "axios";
import Cookies from "js-cookie";
import { trimStart } from "lodash";

export const callAPI = async (
  url,
  method = "get",
  data = {},
  token = undefined,
  host = window.ENV.REACT_APP_API_URL,
  headers
) => {
  const requestOption = {
    method,
    data,
    url: trimStart(url, "/"),
    headers: headers
      ? headers
      : {
          authorization: `Bearer ${token || Cookies.get("loginToken") || ""}`,
          "x-access-token": window.ENV.REACT_APP_FINANCIAL_API_TOKEN,
        },
  };
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    requestOption.baseURL = host;
  }
  return (await axios(requestOption)).data;
};
