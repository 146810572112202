import {
  REGISTRATION_FORM_DATA,
  SET_REGISTRATION_FORM_RESPONSE,
  SET_REGISTRATION_LOADER,
  SET_REPRESENTATIVE_DATA,
} from "../Actions/ActionTypes";

const initialState = {
  isLoading: false,
  updateResponse: "",
  data: {},
  representativeData: [],
};
const RegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION_FORM_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case SET_REGISTRATION_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_REGISTRATION_FORM_RESPONSE:
      return {
        ...state,
        updateResponse: action.payload,
      };
    case SET_REPRESENTATIVE_DATA:
      return {
        ...state,
        representativeData: action.payload,
      };
    default:
      return state;
  }
};

export default RegistrationReducer;
