import classNames from "classnames";
import React from "react";
import { isFragment } from "react-is";
import styles from "./styles.module.scss";

const toArray = (children, option = {}) => {
  let ret = [];

  React.Children.forEach(children, (child) => {
    if ((child === undefined || child === null) && !option.keepEmpty) {
      return;
    }

    if (Array.isArray(child)) {
      ret = ret.concat(toArray(child));
    } else if (isFragment(child) && child.props) {
      ret = ret.concat(toArray(child.props.children, option));
    } else {
      ret.push(child);
    }
  });

  return ret;
};

const Space = ({
  size = "small",
  direction = "horizontal",
  children,
  classes = "",
  align,
  jusitfy,
}) => {
  const itemArray = toArray(children, { keepEmpty: true });

  return (
    <div
      className={classNames(styles.spaceContainer, classes, {
        [styles[size]]: typeof size === "string",
        [styles.vertical]: direction === "vertical",
      })}
      style={{
        alignItems: align,
        justifyContent: jusitfy,
        gap: typeof size === "number" ? `${size}px` : undefined,
      }}
    >
      {itemArray.map((item, index) => (
        <div className={styles.spaceItem} key={index}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default Space;
