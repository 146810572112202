import {
  DEALER_CLOSED_DEAL_SUCCESS,
  DEALER_PENDING_DEAL_SUCCESS,
  SET_DEALDER_LOADER,
  SET_DEALER_DOC_STATUS,
} from "../Actions/ActionTypes";

const initialState = {
  isLoadingDeals: true,
  pendingDeals: [],
  docStatus: [
    { name: "license", text: "Drivers License", checked: true },
    { name: "insurance", text: "Proof of Insurance", checked: true },
    { name: "payStub", text: "Proof of Employment Pay Stub", checked: false },
    {
      name: "payoff",
      text: "Trade-In Vehicle Title /payoff Information",
      checked: true,
    },
    {
      name: "financ",
      text: "Proof of Outside Financing or Cash",
      checked: false,
    },
    { name: "registration", text: "Vehicle Registration", checked: false },
    { name: "funded", text: "Funded", checked: false },
  ],
  closedDeals: [],
};

const BuyerAuctionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEALER_PENDING_DEAL_SUCCESS:
      return {
        ...state,
        pendingDeals: action.payload,
      };
    case SET_DEALER_DOC_STATUS:
      return {
        ...state,
        docStatus: action.payload,
      };
    case SET_DEALDER_LOADER:
      return {
        ...state,
        isLoadingDeals: action.payload,
      };
    case DEALER_CLOSED_DEAL_SUCCESS:
      return {
        ...state,
        closedDeals: action.payload,
      };
    default:
      return state;
  }
};

export default BuyerAuctionReducer;
