import { callAPI } from "@utils/Api";

/**
 * login user
 * @param {*} data
 * @returns
 */
export const login = (data) => callAPI("/user/login", "post", data);

export const changeBidderPassword = ({ data, loginToken }) =>
  callAPI("/user/dealer/employee/password", "post", data, loginToken);

export const refreshToken = () => callAPI("/user/refresh-token");
