import CookieIcon from "@assets/Images/icons/icon-cookie.svg";
import Button from "@components/Button";
import classNames from "classnames";
import React from "react";
import { Card, CardBody } from "reactstrap";
import style from "./style.module.scss";

const CookiePopUp = ({ toggle }) => {
  const onSelection = (value) => {
    localStorage.setItem("cookieAccept", value);
    toggle();
  };

  return (
    <Card className={classNames(style.cookieCard)}>
      <CardBody className="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <img src={CookieIcon} alt="Cookie icon" />
        <p
          className={classNames(
            "flex-grow-1",
            "align-items-center",
            "mb-3",
            "pe-md-2",
            style.cardText
          )}
        >
          We use cookies to ensure that we give you the best experience on our
          website.
        </p>
        <div className="d-flex align-items-center">
          <Button
            id="declineCookies_"
            onClick={() => onSelection(false)}
            className={classNames(style.acceptBtn)}
          >
            Decline
          </Button>
          <Button
            id="allowCookies_"
            filled
            onClick={() => onSelection(true)}
            className={classNames(style.acceptBtn)}
          >
            Allow
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default CookiePopUp;
