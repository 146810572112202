import Button from "@components/Button";
import { Form, InputController } from "@components/FormUtils";
import Modal from "@components/Modal";
import React from "react";
import * as Yup from "yup";
import styles from "./styles.module.scss";

const marks = [
  { value: 25 },
  { value: 50 },
  { value: 100 },
  { value: 200 },
  { value: 300 },
  { value: 400 },
  { value: 500 },
  { value: 1000 },
  { value: 1500 },
  { value: 2000 },
  { value: 3000 },
];

const validationSchema = Yup.object({
  zipcode: Yup.string()
    .matches(/^\d{5}(-\d{4})?$/, "Please enter valid American Zip Code")
    .required("Please enter valid zipcode."),
  distance: Yup.number()
    .integer()
    .min(25, "Distance cannot be less than 25 miles.")
    .max(3000, "Distance cannot be greater than 3000 miles.")
    .required("Please enter Distance between 25 to 3000 miles."),
});

const LocationPopUp = ({ open, toggle, initialLocationValues, onNext }) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      title="Vehicles by location"
      footer={null}
      centered
      filledHeader
      className={styles.form}
    >
      <Form
        onSubmit={onNext}
        validationSchema={validationSchema}
        initialValues={initialLocationValues}
      >
        <InputController
          name="zipcode"
          control="themed"
          label="Enter Zip Code"
        />
        <div className="my-5">
          <InputController
            step={null}
            minimum={25}
            units={"Miles"}
            marks={marks}
            maximum={3000}
            name="distance"
            label="Distance"
            control="slider"
            valueLabelDisplay="off"
          />
        </div>
        <Button
          type="submit"
          className="w-100 mb-4"
          variant="secondary"
          outline
          id="setLocation_"
        >
          Next
        </Button>
      </Form>
    </Modal>
  );
};

export default LocationPopUp;
