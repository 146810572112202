import React from "react";
import { Link, NavLink } from "react-router-dom";

const NavLinksRenderer = ({ navElements }) => {
  return (
    <ul className="navbar-nav ms-auto me-auto mb-2 mb-lg-0">
      {navElements.map((item) => {
        if (!item.isDropdown) {
          return (
            <li className="nav-item" key={item.path}>
              <NavLink
                to={item.path}
                className="nav-link"
                activeClassName="active"
                exact={item.path === "/"}
              >
                {item.text}
              </NavLink>
            </li>
          );
        } else {
          return (
            <li className="nav-item dropdown" key={item.path}>
              <NavLink
                role="button"
                to={item.path}
                id="navbarDropdown"
                aria-expanded="false"
                activeClassName="active"
                data-bs-toggle="dropdown"
                className="nav-link dropdown-toggle"
              >
                {item.text}
              </NavLink>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {item.dropDownItems.map((dropItem) => (
                  <li key={dropItem.path}>
                    <Link className="dropdown-item" to={dropItem.path}>
                      {dropItem.text}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default NavLinksRenderer;
