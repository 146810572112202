import { useState, useEffect } from 'react';

const getDimentions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

const useWindowDimentions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getDimentions());

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getDimentions());
    };
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default useWindowDimentions;