import {
  COLOR_FILTER_TRUE_FALSE,
  FILTER_COLORS,
  FILTER_DRIVE,
  FILTER_ENGINE,
  FILTER_INTERIORS,
  FILTER_MILEAGE,
  FILTER_PRICE,
  INTERIOR_FILTER_TRUE_FALSE,
  MAKE_FILTER_INITIAL,
  PRICE_FILTER_TRUE_FALSE,
} from "./ActionTypes";

export const setfilterPrice = (value) => {
  return {
    type: FILTER_PRICE,
    payload: value,
  };
};

export const isFilteredPrice = (value) => {
  return {
    type: PRICE_FILTER_TRUE_FALSE,
    payload: value,
  };
};

export const setFilterColors = (value) => {
  return {
    type: FILTER_COLORS,
    payload: value,
  };
};

export const isFilteredColors = (value) => {
  return {
    type: COLOR_FILTER_TRUE_FALSE,
    payload: value,
  };
};

export const setFilterInteriors = (value) => {
  return {
    type: FILTER_INTERIORS,
    payload: value,
  };
};

export const isFilteredInteriors = (value) => {
  return {
    type: INTERIOR_FILTER_TRUE_FALSE,
    payload: value,
  };
};

export const setFilterMileage = (value) => {
  return {
    type: FILTER_MILEAGE,
    payload: value,
  };
};

export const setFilterEngine = (value) => {
  return {
    type: FILTER_ENGINE,
    payload: value,
  };
};

export const setFilterDrive = (value) => {
  return {
    type: FILTER_DRIVE,
    payload: value,
  };
};

export const makeFilterInitial = () => {
  return {
    type: MAKE_FILTER_INITIAL,
  };
};
