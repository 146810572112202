import React from "react";
import NumberFormat from "react-number-format";
import ThemeInput from "../ThemeInput";
/**
 *
 * @param {*} props
 * @returns
 */
const phoneNumberFormat = (val) => {
  const cvLength = val.length;
  if (cvLength) {
    if (cvLength < 4) return val;
    if (cvLength < 7) return `${val.slice(0, 3)}-${val.slice(3)}`;
    return `${val.slice(0, 3)}-${val.slice(3, 6)}-${val.slice(6, 10)}`;
  }
};

const NumberInput = (props) => (
  <NumberFormat
    {...props}
    type="text"
    format={props.format ? phoneNumberFormat : null}
    customInput={ThemeInput}
    allowLeadingZeros
  />
);
export default NumberInput;
