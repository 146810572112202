//For the steps screen
export const SELECT_YEAR = "SELECT_YEAR";
export const SELECT_MAKE = "SELECT_MAKE";
export const SELECT_MODEL = "SELECT_MODEL";
export const SELECT_SERIES = "SELECT_SERIES";
export const SELECT_STYLE = "SELECT_STYLE";
export const RETURN_INITIAL = "RETURN_INITIAL";
export const RETURN_MAKE = "RETURN_MAKE";
export const RETURN_MODEL = "RETURN_MODEL";
export const RETURN_SERIES = "RETURN_SERIES";
export const SET_ALL_STEPS_DATA = "SET_ALL_STEPS_DATA";
export const YEAR_REQUEST_STARTED = "YEAR_REQUEST_STARTED";
export const YEAR_REQUEST_SUCCEEDED = "YEAR_REQUEST_SUCCEEDED";
export const YEAR_REQUEST_FAILED = "YEAR_REQUEST_FAILED";
export const MAKE_SELECT_BOX_DATA = "MAKE_SELECT_BOX_DATA";
export const MODEL_SELECT_BOX_DATA = "MODEL_SELECT_BOX_DATA";
export const SERIES_SELECT_BOX_DATA = "SERIES_SELECT_BOX_DATA";
export const STYLE_SELECT_BOX_DATA = "STYLE_SELECT_BOX_DATA";
export const SAVE_SELECTION_STATE = "SAVE_SELECTION_STATE";

//For create vehicle configuration
export const CONFIG_REQUEST_STARTED = "CONFIG_REQUEST_STARTED";
export const CONFIG_REQUEST_SUCCEEDED = "CONFIG_REQUEST_SUCCEEDED";
export const CONFIG_REQUEST_FAILED = "CONFIG_REQUEST_FAILED";
export const CONFIG_DATA_INITIAL = "CONFIG_DATA_INITIAL";

//For Actual Images
export const ACTUAL_IMAGE_REQUEST_STARTED = "ACTUAL_IMAGE_REQUEST_STARTED";
export const ACTUAL_IMAGE_REQUEST_SUCCEEDED = "ACTUAL_IMAGE_REQUEST_SUCCEEDED";
export const ACTUAL_IMAGE_REQUEST_FAILED = "ACTUAL_IMAGE_REQUEST_FAILED";

//For the year make model car
export const YMM_BACKUP_DYN_IMAGE_BG = "YMM_BACKUP_DYN_IMAGE_BG";
export const YMM_REQUEST_SUCCEEDED = "YMM_REQUEST_SUCCEEDED";
export const YMM_REQUEST_STARTED = "YMM_REQUEST_STARTED";
export const YMM_REQUEST_FAILED = "YMM_REQUEST_FAILED";
export const YMM_DATA_INITIAL = "YMM_DATA_INITIAL";

//to get the steps background according to year make model:
export const YMM_STOCK_IMG_REQUEST_STARTED = "YMM_STOCK_IMG_REQUEST_STARTED";
export const YMM_STOCK_IMG_REQUEST_SUCCEEDED =
  "YMM_STOCK_IMG_REQUEST_SUCCEEDED";
export const YMM_STOCK_IMG_REQUEST_FAILED = "YMM_STOCK_IMG_REQUEST_FAILED";

//For the year stock images
export const STOCK_REQUEST_STARTED = "STOCK_REQUEST_STARTED";
export const STOCK_REQUEST_SUCCEEDED = "STOCK_REQUEST_SUCCEEDED";
export const STOCK_REQUEST_FAILED = "STOCK_REQUEST_FAILED";
export const STOCK_IMAGES_INITIAL = "STOCK_IMAGES_INITIAL";

// For vehicle Inventory
export const INVENTORY_CAR_SELECT = "INVENTORY_CAR_SELECT";
export const SET_INVENTORY_CAR_LIST = "SET_INVENTORY_CAR_LIST";
export const UPDATE_INVENTORY_CAR_LIST = "UPDATE_INVENTORY_CAR_LIST";
export const MAKE_INVENTORY_INITIAL = "MAKE_INVENTORY_INITIAL";

//For Car Actual data from vin
export const CAR_ACTUAL_DATA_VIN_STARTED = "CAR_ACTUAL_DATA_VIN_STARTED";
export const CAR_ACTUAL_DATA_VIN_SUCCEEDED = "CAR_ACTUAL_DATA_VIN_SUCCEEDED";
export const CAR_ACTUAL_DATA_VIN_FAILED = "CAR_ACTUAL_DATA_VIN_FAILED";

//for filter in inventory car list
export const FILTER_PRICE = "FILTER_PRICE";
export const FILTER_COLORS = "FILTER_COLORS";
export const FILTER_INTERIORS = "FILTER_INTERIORS";
export const FILTER_MILEAGE = "FILTER_MILEAGE";
export const FILTER_ENGINE = "FILTER_ENGINE";
export const FILTER_DRIVE = "FILTER_DRIVE";

export const COLOR_FILTER_TRUE_FALSE = "COLOR_FILTER_TRUE_FALSE";
export const PRICE_FILTER_TRUE_FALSE = "PRICE_FILTER_TRUE_FALSE";
export const INTERIOR_FILTER_TRUE_FALSE = "INTERIOR_FILTER_TRUE_FALSE";
export const MAKE_FILTER_INITIAL = "MAKE_FILTER_INITIAL";

//Buyer auction
export const BUYER_AUCTION_ADD_BID = "BUYER_AUCTION_ADD_BID";
export const BUYER_AUCTION_BID_CLEAR = "BUYER_AUCTION_BID_CLEAR";

//Buyer auction retrieve data
export const BUYER_AUCTION_RETRIEVE_DATA_STARTED =
  "BUYER_AUCTION_RETRIEVE_DATA_STARTED";
export const BUYER_AUCTION_RETRIEVE_DATA_SUCCEEDED =
  "BUYER_AUCTION_RETRIEVE_DATA_SUCCEEDED";
export const BUYER_AUCTION_RETRIEVE_DATA_FAILED =
  "BUYER_AUCTION_RETRIEVE_DATA_FAILED";

// Dealer action
export const DEALER_PENDING_DEAL_SUCCESS = "DEALER_PENDING_DEAL";
export const SET_DEALER_DOC_STATUS = "SET_DEALER_DOC_STATUS";
export const SET_DEALDER_LOADER = "SET_DEALDER_LOADER";
export const DEALER_CLOSED_DEAL_SUCCESS = "DEALER_CLOSED_DEAL_SUCCESS";

// Dealer onboarding
export const SET_STEP_ACTIVE = "SET_STEP_ACTIVE";
export const INCREMENT_STEP = "INCREMENT_STEP";

//Registration action
export const REGISTRATION_FORM_DATA = "REGISTRATION_FORM_DATA";
export const SET_REGISTRATION_LOADER = "SET_DEALDER_LOADER";
export const SET_REGISTRATION_FORM_RESPONSE = "SET_REGISTRATION_FORM_RESPONSE";
export const SET_REPRESENTATIVE_DATA = "SET_REPRESENTATIVE_DATA";

// Sidescroll Actions
export const STEP_VISITED = "STEP_VISITED";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";

// auth modal

export const TOGGLE_AUTH_MODAL = "TOGGLE_AUTH_MODAL";
