import { ErrorMessage, Field } from "formik";
import React from "react";
import InputErr from "./InputErr";

const Input = ({ label, name, ...rest }) => (
  <div className="form-group mb-4">
    {!!label && (
      <label htmlFor={name} className="text-simple">
        {label}
      </label>
    )}
    <Field id={name} name={name} {...rest} />
    <ErrorMessage component={InputErr} name={name} />
  </div>
);

export default Input;
