import AuctionProcessSidebar from "@components/AuctionProcessSidebar";
import DrawerToggle from "@components/DrawerToggle";
import Loader from "@components/Loader";
import { useAuction } from "@hooks/auction";
import { useJacketData } from "@hooks/jacket";
import useAuth from "@hooks/useAuth";
import { useToggle } from "@hooks/useToggle";
import { useVehicleConfiguration } from "@hooks/vehicle-configuration";
import classNames from "classnames";
import { get } from "lodash";
import React, { createContext, lazy, Suspense } from "react";
import { Route, Switch, useHistory, useParams } from "react-router";
import { Card, CardBody, Col, Row } from "reactstrap";
import styles from "./styles.module.scss";

const PersonalDetail = lazy(() =>
  import("@pages/AuctionProcess/PersonalDetail")
);

const Insurance = lazy(() => import("@pages/AuctionProcess/Insurance/index"));

const TradeIn = lazy(() => import("@pages/AuctionProcess/TradeIn/TradeIn"));

const Incentives = lazy(() =>
  import("@pages/AuctionProcess/Incentives/Incentives")
);

const PreQualifyAutoLoan = lazy(() =>
  import("@pages/AuctionProcess/PreQualifyAutoLoan")
);

const PaymentSummary = lazy(() =>
  import("@pages/AuctionProcess/PaymentSummary")
);

const AuctionInventory = lazy(() =>
  import("@pages/AuctionProcess/StartAuction")
);

export const JacketDetailsContext = createContext({});

const AuctionProcessRouter = () => (
  <Switch>
    <Suspense fallback={<div>Loading...</div>}>
      <Route
        component={PersonalDetail}
        path={`/process/:id/personal-details`}
      />
      <Route component={TradeIn} path={`/process/:id/trade-in`} />
      <Route component={Incentives} path={`/process/:id/incentives`} />
      <Route component={PreQualifyAutoLoan} path={`/process/:id/pre-qualify`} />
      <Route component={PaymentSummary} path={`/process/:id/payment-summary`} />
      <Route component={AuctionInventory} path={`/process/:id/start-auction`} />
      <Route component={Insurance} path={`/process/:id/insurance`} />
    </Suspense>
  </Switch>
);

const Main = () => {
  const index = "east";
  const { id } = useParams();
  const [isOpen, togglePopUp] = useToggle();
  const history = useHistory();
  const { type } = useAuth();
  const {
    isLoading,
    data: jacketResponse,
    isError,
    refetch,
  } = useJacketData(id);

  const jacketDetails = get(jacketResponse, "_source", {});
  const configId = get(jacketDetails, "payload.vehicle_configuration_id");
  const { loading: isLoadingVehicleConfig, data: vehicleConfigInfo } =
    useVehicleConfiguration(configId);

  const vehicleConfig = get(vehicleConfigInfo, "_source.payload");

  // only buyers can see this page
  if (type && type !== "buyer") {
    history.push("/");
  }

  return (
    <JacketDetailsContext.Provider
      value={{
        id: id,
        details: { ...jacketDetails, index },
        refetch,
        vehicleConfig,
        configId,
      }}
    >
      <Loader isLoading={isLoading || isLoadingVehicleConfig} />
      <main className="gradient-bg-reverse mt-5 pt-2">
        <div className={styles.container}>
          <Row className="px-0 g-0 position-relative overflow-hidden">
            <Col
              lg={8}
              xl={9}
              className={classNames("p-0", styles.auctionProcessCol)}
            >
              <Card className={styles.auctionForm}>
                <CardBody>
                  {isError && <p>Auction not found</p>}
                  {!isLoading && !isError && !isLoadingVehicleConfig ? (
                    jacketDetails.state === "pending" ? (
                      <AuctionProcessRouter />
                    ) : (
                      <p>
                        The auction has either started already or completed
                        already.
                      </p>
                    )
                  ) : null}
                </CardBody>
              </Card>
            </Col>
            {isOpen && <Col onClick={togglePopUp} className={styles.overlay} />}
            <aside
              className={classNames(
                "p-0",
                "m-0",
                "col-lg-4",
                "col-xl-3",
                "mobile-drawer",
                {
                  open: isOpen,
                }
              )}
            >
              <Card className={styles.auctionProcess}>
                <DrawerToggle isOpen={isOpen} handleToggle={togglePopUp} />
                <AuctionProcessSidebar />
              </Card>
            </aside>
          </Row>
        </div>
      </main>
    </JacketDetailsContext.Provider>
  );
};

export default Main;
