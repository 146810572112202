import PaymentCalculator from "@components/PaymentCalculator";
import useAuth from "@hooks/useAuth";
import { getCreditScore } from "@utils/credit-score";
import React, { useEffect, useMemo, useState } from "react";

const PaymentCalculatorWrapper = (props, ref) => {
  const [downPayment, setDownPayment] = useState(props.downPayments || 500);
  const [terms, setTerm] = useState(72);
  const { user } = useAuth();

  const creditScore = useMemo(() => getCreditScore(user), [user]);

  const [ficoScore, setFicoScore] = useState(props.loanTerm || creditScore);

  useEffect(() => {
    if (creditScore) setFicoScore(creditScore);
  }, [creditScore]);

  return (
    <PaymentCalculator
      ref={ref}
      ficoScore={ficoScore}
      setFicoScore={setFicoScore}
      setDownPayment={setDownPayment}
      downPayment={downPayment}
      loanTerms={terms}
      setTerm={setTerm}
      {...props}
    />
  );
};

export default React.forwardRef(PaymentCalculatorWrapper);
