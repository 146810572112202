import {
  YMM_BACKUP_DYN_IMAGE_BG,
  YMM_DATA_INITIAL,
  YMM_REQUEST_FAILED,
  YMM_REQUEST_STARTED,
  YMM_REQUEST_SUCCEEDED,
  YMM_STOCK_IMG_REQUEST_FAILED,
  YMM_STOCK_IMG_REQUEST_STARTED,
  YMM_STOCK_IMG_REQUEST_SUCCEEDED,
} from "../Actions/ActionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  stepBgDyn: "",
  backUpBgDyn: "",
  backUpVehicleData: null,
};

const YmmReducer = (state = initialState, action) => {
  switch (action.type) {
    case YMM_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true,
      };

    case YMM_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case YMM_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case YMM_DATA_INITIAL:
      return {
        error: null,
        isLoading: false,
        backUpVehicleData: null,
        stepBgDyn: state.backUpBgDyn || "",
        data: state.backUpVehicleData || null,
      };
    //this portion is for the steps bg using year make model
    case YMM_STOCK_IMG_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case YMM_STOCK_IMG_REQUEST_SUCCEEDED:
      return {
        ...state,
        error: null,
        backUpBgDyn: "",
        isLoading: false,
        stepBgDyn: action.payload,
      };

    case YMM_BACKUP_DYN_IMAGE_BG:
      return {
        ...state,
        backUpBgDyn: state.stepBgDyn,
        backUpVehicleData: state.data,
      };

    case YMM_STOCK_IMG_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default YmmReducer;
