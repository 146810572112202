import {
  IconDeliveredCarMap,
  IconMapCarDealer,
  IconMaterialGavel,
} from "@assets/Images";
import Button from "@components/Button";
import classNames from "classnames";
import React from "react";
import { Col, Row } from "reactstrap";
import styles from "./styles.module.scss";

const steps = [
  {
    img: IconMapCarDealer,
    num: "1",
    title: "Find Your Car in Minutes",
    point1: "Browse millions of cars in inventory",
    point2: "Select the cars you would like to buy",
    point3: "Add them into your select inventory",
    action: "Lets Do This!",
  },
  {
    img: IconMaterialGavel,
    num: "2",
    title: "Real-Time Live Auction",
    point1: "Watch hundreds of Dealers bid down the price",
    point2: "Then review your lowest bids",
    point3: "Pick the deal you like the best",
    action: "We've Got Your Back!",
  },
  {
    img: IconDeliveredCarMap,
    num: "3",
    title: "Delivered To Your Home",
    point1: "Sign the paperwork",
    point2: "Dealer schedules delivery to your location",
    point3: "Your new car delivered to your front door",
    action: "When Dealers Bid, You Win!",
  },
];

const FindYourCar = () => {
  return (
    <div>
      <Row>
        {steps.map((step) => (
          <Col lg={4} key={step.num} className="ps-5 pe-5">
            <div className="w-100 d-flex justify-content-center mb-4">
              <img alt="car" src={step.img} className={styles.img} />
            </div>
            <h4 className={styles.title}>{step.title}</h4>
            <div
              className={classNames(
                styles.list,
                "my-3 d-flex justify-content-center"
              )}
            >
              <ul>
                <li>{step.point1}</li>
                <li>{step.point2}</li>
                <li>{step.point3}</li>
              </ul>
            </div>

            <h4 className={classNames(styles.action, "mb-4 text-center")}>
              {step.action}
            </h4>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FindYourCar;
