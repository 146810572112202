import { useCarImage, useImage } from "@hooks/image";
import classNames from "classnames";
import { first } from "lodash";
import React, { useRef } from "react";
import { useInViewport } from "react-in-viewport";

const CarImage = ({ size = "small", vin, className }) => {
  const imageRef = useRef();
  const { inViewport: isImageVisibile } = useInViewport(imageRef);
  const {
    data = {
      image_urls: [],
    },
  } = useCarImage(vin, {
    enabled: isImageVisibile,
  });

  const imageUrl = useImage(`${first(data.image_urls)}?size=${size}`);

  return (
    <img
      src={imageUrl}
      className={classNames(className, "img-fluid")}
      alt="car"
      ref={imageRef}
    />
  );
};

export default CarImage;
