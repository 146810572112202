import Button from "@components/Button";
import NumberInput from "@components/NumberInput";
import Terms from "@components/TermsConditions";
import ThemeInput from "@components/ThemeInput";
import { DEFAULT_ERROR_MESSAGE } from "@constants/index";
import Checkbox from "@material-ui/core/Checkbox";
import { BuyerSignupValidation } from "@utils/validations/Auth";
import { isYupError, parseYupError } from "@utils/Yup";
import classNames from "classnames";
import { get } from "lodash-es";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { toggleAuthModal } from "src/redux/Actions/Auth";
import { SHOW_TOAST } from "src/redux/Actions/ToastActions";
import { callAPI } from "src/utils/Api";
import styles from "../styles.module.scss";

const RenderForm = () => {
  const onSuccess = useSelector((state) => state.authModalReducer.onSuccess);

  const [form, setForm] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    accept: false,
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    accept: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setForm({ ...form, [name]: type === "checkbox" ? checked : value });
    setErrors({ ...errors, [name]: "" });
    if (name === "phone" && value.replaceAll("-", "").length < 10) {
      setErrors({
        ...errors,
        [name]: "Phone number should be 10 number long.",
      });
    }
  };
  const SignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    try {
      const phone = form.phone.replaceAll("-", "");
      await BuyerSignupValidation.validate(
        { ...form, phone },
        {
          abortEarly: false,
        }
      );
      const { fullName, email, password, confirmPassword } = form;
      await callAPI("user/buyer/registration", "POST", {
        fullName,
        email,
        password,
        confirmPassword,
        phone,
      });
      dispatch({
        type: SHOW_TOAST,
        payload: {
          type: "success",
          message: "Account created succcessfully, you can login now.",
        },
      });
      dispatch(toggleAuthModal("signin", onSuccess));
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        const message = get(
          error,
          "response.data.message",
          DEFAULT_ERROR_MESSAGE
        );
        dispatch({
          type: SHOW_TOAST,
          payload: {
            type: "error",
            message,
          },
        });
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <form onSubmit={SignUp} className={styles.form}>
        <Row>
          <Col lg={12} sm={12}>
            <ThemeInput
              type="text"
              name="fullName"
              value={form.fullName}
              onChange={handleChange}
              placeholder="Full Name"
              label="Full Name"
              showRequiredMark
              error={errors.fullName}
            />
          </Col>
          <Col lg={12} sm={12}>
            <ThemeInput
              type="text"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Email Address"
              label="Email Address"
              showRequiredMark
              error={errors.email}
            />
            <NumberInput
              name="phone"
              value={form.phone}
              onChange={handleChange}
              placeholder="Phone Number"
              label="Phone Number"
              showRequiredMark
              error={errors.phone}
              format={"phone"}
            />

            <ThemeInput
              type="password"
              name="password"
              value={form.password}
              onChange={handleChange}
              placeholder="Password"
              label="Password"
              showRequiredMark
              error={errors.password}
            />

            <ThemeInput
              type="password"
              name="confirmPassword"
              value={form.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm Password"
              label="Confirm Password"
              showRequiredMark
              error={errors.confirmPassword}
            />
            <p className={styles.paraDesc1}>
              Requires at least 8 digits, one capital letter, one number and one
              special character.
            </p>

            <Checkbox
              checked={Boolean(form.accept)}
              onChange={handleChange}
              name="accept"
              id="accept_"
              color="primary"
              style={{
                padding: 0,
                marginRight: "4px",
              }}
            />
            <label
              htmlFor="accept"
              className={classNames(styles.label, "mt-3")}
            >
              I agree with the <Terms />.
            </label>
            {errors.accept && (
              <div className="text-danger invalid-feedback d-block">
                {errors.accept}
              </div>
            )}
            <Button
              className="my-4"
              type="submit"
              size="sm"
              isLoading={isLoading}
              id="signUp_"
              variant="secondary"
              block
            >
              SIGN UP
            </Button>
            <div className={styles.setCenter}>
              <div className={styles.floatRight}>
                <p className={styles.paraDesc1}>
                  Already have an account?{" "}
                  <span
                    className={styles.textBlue}
                    id="signInRedirect_"
                    onClick={() =>
                      dispatch(toggleAuthModal("signin", onSuccess))
                    }
                  >
                    Sign In
                  </span>
                </p>
              </div>
            </div>
          </Col>
        </Row>
        {/* <SocialLogin /> */}
      </form>
    </>
  );
};

export default RenderForm;
