import {
  BUYER_AUCTION_RETRIEVE_DATA_FAILED,
  BUYER_AUCTION_RETRIEVE_DATA_STARTED,
  BUYER_AUCTION_RETRIEVE_DATA_SUCCEEDED,
} from "../Actions/ActionTypes";

const initialState = {
  data: null,
  isLoading: true,
  error: null,
};

const BuyerAuctionRetrieveReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUYER_AUCTION_RETRIEVE_DATA_STARTED:
      return {
        ...state,
        isLoading: true,
      };
    case BUYER_AUCTION_RETRIEVE_DATA_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case BUYER_AUCTION_RETRIEVE_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default BuyerAuctionRetrieveReducer;
