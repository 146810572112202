import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { ErrorMessage, Field } from "formik";
import { snakeCase } from "lodash";
import React from "react";
import InputErr from "./InputErr";

const RadioControl = ({ label, name, options = [], row = false, ...rest }) => (
  <FormControl component="fieldset">
    {!!label && <FormLabel component="legend">{label}</FormLabel>}
    <RadioGroup row={row} aria-label="gender" name="row-radio-buttons-group">
      <Field name={name} {...rest}>
        {({ field }) =>
          options.map(({ value, disabled = false, key }) => (
            <FormControlLabel
              {...field}
              key={key}
              label={key}
              value={value}
              disabled={disabled}
              control={<Radio />}
              id={`${snakeCase(name)}`}
            />
          ))
        }
      </Field>
    </RadioGroup>
    <ErrorMessage name={name} component={InputErr} />
  </FormControl>
);

export default RadioControl;
