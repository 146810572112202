import {
  STOCK_IMAGES_INITIAL,
  STOCK_REQUEST_FAILED,
  STOCK_REQUEST_STARTED,
  STOCK_REQUEST_SUCCEEDED,
} from "../Actions/ActionTypes";

const initialState = {
  data: null,
  isLoading: true,
  error: null,
};

const StockImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case STOCK_REQUEST_STARTED:
      return {
        ...state,
        isLoading: true,
      };

    case STOCK_REQUEST_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };

    case STOCK_REQUEST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    case STOCK_IMAGES_INITIAL:
      return {
        ...initialState,
      };

    default:
      return {
        ...state,
      };
  }
};
export default StockImagesReducer;
