import {
  COLOR_FILTER_TRUE_FALSE,
  FILTER_COLORS,
  FILTER_DRIVE,
  FILTER_ENGINE,
  FILTER_INTERIORS,
  FILTER_MILEAGE,
  FILTER_PRICE,
  INTERIOR_FILTER_TRUE_FALSE,
  MAKE_FILTER_INITIAL,
  PRICE_FILTER_TRUE_FALSE,
} from "../Actions/ActionTypes";

const initialState = {
  price: null,
  colors: null,
  interiors: null,
  mileage: null,
  engine: null,
  drive: null,
  isFiltered: {
    price: false,
    colors: false,
    interiors: false,
    mileage: false,
    engine: false,
  },
};

const FilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILTER_PRICE:
      return {
        ...state,
        price: action.payload,
      };
    case PRICE_FILTER_TRUE_FALSE:
      return {
        ...state,
        isFiltered: {
          ...state.isFiltered,
          price: action.payload,
        },
      };
    case FILTER_COLORS:
      return {
        ...state,
        colors: action.payload,
      };
    case COLOR_FILTER_TRUE_FALSE:
      return {
        ...state,
        isFiltered: {
          ...state.isFiltered,
          colors: action.payload,
        },
      };
    case FILTER_INTERIORS:
      return {
        ...state,
        interiors: action.payload,
      };
    case INTERIOR_FILTER_TRUE_FALSE:
      return {
        ...state,
        isFiltered: {
          ...state.isFiltered,
          interiors: action.payload,
        },
      };
    case FILTER_MILEAGE:
      return {
        ...state,
        mileage: action.payload,
      };
    case FILTER_ENGINE:
      return {
        ...state,
        engine: action.payload,
      };
    case FILTER_DRIVE:
      return {
        ...state,
        drive: action.payload,
      };
    case MAKE_FILTER_INITIAL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default FilterReducer;
