import {
  INVENTORY_CAR_SELECT,
  MAKE_INVENTORY_INITIAL,
  SET_INVENTORY_CAR_LIST,
  UPDATE_INVENTORY_CAR_LIST,
} from "../Actions/ActionTypes";

const initialState = {
  selectedVin: "",
  selectedIndex: "",
  list: null,
  carsType: "",
};

const InventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVENTORY_CAR_LIST:
      return {
        ...state,
        list: action.payload.invCars,
        carsType: action.payload.carsType,
      };
    case UPDATE_INVENTORY_CAR_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case INVENTORY_CAR_SELECT:
      return {
        ...state,
        selectedVin: action.payload.vin,
        selectedIndex: action.payload.index,
      };
    case MAKE_INVENTORY_INITIAL:
      return {
        ...initialState,
      };
    default:
      return {
        ...state,
      };
  }
};
export default InventoryReducer;
