export const DEFAULT_ERROR_MESSAGE =
  "An unknown error occurred while completing your request.";

export const MINIMUM_FICO_SCORE = 600;
export const DEFAULT_TAX_FICO_SCORE = 750;
export const SHOULD_SHOW_SIGNUP = () =>
  String(window.ENV.REACT_APP_SHOW_SIGN_UP) === "true";

//dealer onboarding
export const BUSINESS_DETAILS = "BUSINESS_DETAILS";
export const DEALER_BILLING_COSTS = "DEALER_BILLING_COSTS";
export const DEALER_REPRESENTATIVES = "DEALER_REPRESENTATIVES";
export const DEALER_SUMMARY = "DEALER_SUMMARY";

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const DEFAULT_DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm A";

export const DEFAULT_LOAN_TERMS = 72;
export const DEFAULT_DOWN_PAYMENT = 500;

export const COMPANY_FIRSTNAME = "Bid";
export const COMPANY_LASTNAME = "Wizer";
export const COMPANY_NAME = `${COMPANY_FIRSTNAME}${COMPANY_LASTNAME}`;
export const COMPANY_NAME_ID = "BWZR001-01";
export const COMPANY_EMAIL = "contact@BidWizer.com";
export const COMPANY_CONTACT_NUMBER = "1+(800) 920-6912";

export const MOBILE_SCREEN_WIDTH = 767.98;
export const MOBILE_SCREEN_HEIGHT = 414;

export const AUCTION_AMOUNT = 500;

export const MAX_OFFER_CAN_SELECT = 3;

export const TRADE_IN_NOT_AVAILABLE_TEXT = "not-available";

export const DATA_TYPES = {
  AUCTION: "auction",
  TRADE_IN: "trade",
  VEHICLE: "vehicle",
  VEHICLE_CONFIGURATION: "vehicle_configuration",
  BUYER: "buyer",
  DEALER: "dealer",
  TRADE: "trade",
  JACKET: "jacket",
  BIDDER: "bidder",
};

export const JACKET_STATUS = {
  SHIPPED: "complete",
  ACTIVE: "active",
  CANCEL: "cancel",
  PENDING: "pending",
};

export const JACKET_STATE = {
  dealer_signed: "Dealer Signed",
  buyer_signed: "Buyer Signed",
  pending: "Pending",
  complete: "Completed",
  cancel: "Cancelled",
  funding: "Funding",
};

export const INACTIVITY_TIME_IN_SECONDS = 15 * 60;
export const LIST_REFETCH_INTERVAL = 5000;

export const MAX_TIMER_FOR_VEHICLE_SELECTION_MINUTES = 5;

export const PAYMENT_METHODS = {
  CASH: "cash",
  LOAN: "loan",
  LEASE: "lease",
  RETAIL: "retail",
};

export const AUCTION_STATUS = {
  DECLINED: "declined",
  EXPIRING: "expiring",
  ACTIVE: "active",
  INACTIVE: "inactive",
  LIVE: "live",
  SCHEDULED: "scheduled",
  SELECTION_EXPIRED: "selection_expired",
  COMPLETE: "complete",
};

export const CAR_TYPE = {
  NEW: "new",
  USED: "used",
};

export const zipcodeRegex = /^\d{5}(-\d{4})?$/;

export const HIDE_PREQUALIFY = () =>
  String(window.ENV.REACT_APP_DISABLE_PREQUALIFY) === "true";

export const LOAN_TERMS_OPTIONS = [
  { value: 36, label: "36 Months" },
  { value: 60, label: "60 Months" },
  { value: 72, label: "72 Months" },
  { value: 84, label: "84 Months" },
];

export const LEASE_TERMS_OPTIONS = [
  { value: 36, label: "36 Months" },
  { value: 39, label: "39 Months" },
];

export const LEASE_ANUAL_MILEAGE = 10000;

export const SELECTED_INTEREST_RATE = 3.75;
export const EXTRA_INTEREST_RATE = 1.25;
