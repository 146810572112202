import CalculatorScreen from "@components/Calculator/CalculatorScreen";
import EstimatedCreditScoreDropdown from "@components/EstimatedCreditScoreDropdown";
import ThemeInput from "@components/ThemeInput";
import {
  CAR_TYPE,
  LEASE_TERMS_OPTIONS,
  LOAN_TERMS_OPTIONS,
  SELECTED_INTEREST_RATE,
  EXTRA_INTEREST_RATE,
} from "@constants/";
import useAuth from "@hooks/useAuth";
import { getCreditScore } from "@utils/credit-score";
import { calculateEMI } from "@utils/Payment";
import classNames from "classnames";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { MdClose } from "react-icons/md";
import "./styles.scss";

const DOWN_PAYMENT_REQUIRED_MSG = "Please Enter Your Down Payment";
const DOWN_PAYMENT_ERROR_MSG = "Minimum  Down Payment Is $500";

const PaymentCalculator = (
  {
    closeModal,
    carType = CAR_TYPE.NEW,
    budget = 20000,
    type = "others",
    closeAble = false,
    preSelectedCreditScore,
    disabledInterestRate,
    isInline = false,
    isLoan = true,
    loanTerms = 72,
    setTerm,
    downPayment = 500,
    setDownPayment,
    hasDownPaymentRequired = false,
    residualValue = 0,
    moneyFactor,
    taxRate,
    selectedInterestRate: selectedRate,
    ficoScore,
    setFicoScore,
  },
  ref
) => {
  let selectedInterestRate = SELECTED_INTEREST_RATE + EXTRA_INTEREST_RATE;
  const [interestRates, setInterestRates] = useState([]);
  const [vehicleBudget, setVehicleBudget] = useState(budget);
  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState(0);
  const [downPaymentRequiredText, setDownPaymentRequiredText] = useState("");

  const { user, isAuthenticated } = useAuth();

  const creditScore = useMemo(() => getCreditScore(user), [user]);
  const [score, setScore] = useState(creditScore);

  useEffect(() => {
    if (!isAuthenticated || !creditScore) {
      setScore(720.5);
    } else {
      setScore(creditScore);
    }
  }, [isAuthenticated, user]);

  const [calculated, setCalculated] = useState({
    loanTerms,
    downPayment,
    selectedInterestRate: Number(selectedRate),
  });
  const getDownPaymentErrorMessage = (value = 0) => {
    let errorMsg = "";
    if (!value || (value && !Number(value))) {
      errorMsg = DOWN_PAYMENT_REQUIRED_MSG;
    }
    if (value && Number(value) < 500) {
      errorMsg = DOWN_PAYMENT_ERROR_MSG;
    }
    return errorMsg;
  };
  useEffect(() => {
    setCalculated(() => ({
      selectedInterestRate,
      loanTerms,
      downPayment,
    }));
    const errorMsg = getDownPaymentErrorMessage(downPayment);
    if (hasDownPaymentRequired) {
      setDownPaymentRequiredText(errorMsg);
    }
  }, [loanTerms, downPayment, selectedInterestRate, ficoScore]);

  const calculateEmis = ({ target: { value: val, name } }) => {
    const value = Number(val);
    let { downPayment } = calculated;
    if (name === "loanTerms") {
      loanTerms = value > 0 ? Number(value) : 0;
    }
    if (name === "downPayment") {
      downPayment = value > 0 ? Number(value) : 0;
      setDownPayment(downPayment);
    }
    if (name === "selectedInterestRate") {
      selectedInterestRate = value > 0 ? Number(value) : 0;
      setScore(Number(value));
      setFicoScore(Number(value));
    }
  };
  useEffect(() => {
    const { emi } = calc({
      ...calculated,
      loanTerms,
      downPayment,
      selectedInterestRate,
    });
    setEstimatedMonthlyPayment(emi);
    setCalculated({
      loanTerms,
      downPayment,
      selectedInterestRate,
    });
  }, [selectedInterestRate, loanTerms, downPayment]);

  const handleDownPaymentChange = (value) => {
    const errorMsg = getDownPaymentErrorMessage(downPayment);

    if (hasDownPaymentRequired) {
      setDownPaymentRequiredText(errorMsg);
    }
    calculateEmis({ target: { name: "downPayment", value } });
  };

  const calc = ({ loanTerms, principle, downPayment, selectedInterestRate }) =>
    calculateEMI(
      principle ? principle : vehicleBudget,
      downPayment,
      selectedInterestRate,
      loanTerms,
      residualValue
    );
  const fetchInterest = async (tiers) => {
    const inerestRatesBasedOnCarType = tiers.map((t) => {
      return {
        ...t,
        interest:
          carType === CAR_TYPE.USED
            ? t.used_car_percentage
            : t.new_car_percentage,
      };
    });
    setInterestRates(inerestRatesBasedOnCarType);
    const { emi } = calc(calculated);
    setEstimatedMonthlyPayment(emi);
  };

  const handleChangeBudget = ({ target: { value } }) => {
    let val = Number(value) ? Number(value) : 0;
    let { emi } = calc({ ...calculated, principle: Number(val) });
    setEstimatedMonthlyPayment(emi);
    setVehicleBudget(Number(val));
  };

  const updateEMI = ({ target: { value } }) => {
    if (type === "others") {
      const estMonthPay = value ? Number(value) : 0;
      const { loanTerms, selectedInterestRate } = calculated;
      let r = selectedInterestRate / 12 / 100;
      let p =
        estMonthPay /
        (r * (Math.pow(1 + r, loanTerms) / (Math.pow(1 + r, loanTerms) - 1)));
      p = p < 0 ? 0 : p;
      p = Math.round(p);
      setEstimatedMonthlyPayment(estMonthPay);
      setVehicleBudget(p + calculated.downPayment);
    } else {
      const estMonthPay = value ? Number(value) : 0;
      const { loanTerms, selectedInterestRate } = calculated;
      let r = selectedInterestRate / 12 / 100;
      const r1 = Math.pow(1 + r, loanTerms);
      const numerator = estMonthPay * (r1 - 1);
      const denominator = r * r1;
      const div = numerator / denominator;
      const downPaymentCalculated =
        vehicleBudget - div > 0 ? vehicleBudget - div : 0;

      setCalculated({
        ...calculated,
        downPayment: Math.ceil(downPaymentCalculated),
      });

      setEstimatedMonthlyPayment(estMonthPay);
    }
  };

  useEffect(() => {
    setVehicleBudget(budget);
  }, [budget]);

  useEffect(() => {
    const interestRate = interestRates.find((int) => {
      // if user has credit score get it from his score
      if (creditScore) {
        return int.fico_low < creditScore && int.fico_high > creditScore;
      }
      return (
        `${int.name}: ${int.fico_low} - ${int.fico_high}` ===
        preSelectedCreditScore
      );
    })?.[
      carType === CAR_TYPE.NEW ? "new_car_percentage" : "used_car_percentage"
    ];
    if (interestRate) {
      setCalculated((prev) => ({
        ...prev,
        selectedInterestRate,
      }));
    }
  }, [
    preSelectedCreditScore,
    interestRates,
    creditScore,
    carType,
    selectedInterestRate,
  ]);

  useImperativeHandle(ref, () => ({
    getData: () => ({
      calculated,
      vehicleBudget,
      estimatedMonthlyPayment,
      residualValue,
      taxRate,
      moneyFactor,
    }),
  }));

  return (
    <div className="loanCalulator-dark w-60 shadow-md">
      <div className="heading px-4 py-4 d-flex justify-content-between align-items-center">
        <span>Payment Calculator</span>
        {closeAble && (
          <MdClose size={25} onClick={closeModal} className="cursor-pointer" />
        )}
      </div>
      <div
        className={classNames({
          "d-lg-flex d-md-flex-col isInline py-2 pb-2": isInline,
        })}
      >
        <CalculatorScreen
          type="text"
          maxLength={11}
          name="vahicleBudget"
          value={vehicleBudget}
          label="Vehicle Budget"
          id="vehicleBudget_input"
          disabled={type !== "others"}
          onChange={handleChangeBudget}
          className="form-control-text shadow-md"
        />
        <CalculatorScreen
          type="text"
          maxLength={11}
          onChange={updateEMI}
          id="vehicleEmi_input"
          name="estimatedMonthlyPayment"
          value={estimatedMonthlyPayment}
          label="Estimated Monthly Payment"
          className="form-control-text shadow-md"
        />
      </div>
      <div className="w-100 formFieldWrapper px-3 pt-3">
        <EstimatedCreditScoreDropdown
          id="selectCreditScore"
          onChange={calculateEmis}
          value={calculated.selectedInterestRate}
          name="selectedInterestRate"
          label="Select Your Credit Score"
          isDisabled={disabledInterestRate}
          setInterestRateAsValue
          useNewCarInterest={carType === CAR_TYPE.NEW}
          getTiers={fetchInterest}
          creditScore={creditScore}
          setScore={setScore}
          score={score}
        />
        <div
          className={classNames({
            "d-lg-flex gap-3": isInline,
          })}
        >
          <span className={classNames({ "mt-3": isInline })}>
            <ThemeInput
              className="pt-3"
              type="select"
              name="loanTerms"
              showRequiredMark
              onChange={(e) => {
                calculateEmis(e);
                if (setTerm) setTerm(e.target.value);
              }}
              value={calculated.loanTerms}
              label={
                isLoan ? "Select Your Loan Term" : "Select Your Lease Term"
              }
              labelClass="form-label text-bold"
              options={isLoan ? LOAN_TERMS_OPTIONS : LEASE_TERMS_OPTIONS}
            />
          </span>
          <div className="form-group pb-1">
            <ThemeInput
              prefix="$ "
              id="downpayment"
              name="input-name"
              value={downPayment}
              placeholder="Please enter a number"
              label="Enter Your Down Payment"
              onChange={(e) => handleDownPaymentChange(e.target.value)}
              className="form-control-text form-control-text-dp "
              showRequiredMark
              error={downPaymentRequiredText}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(PaymentCalculator);
