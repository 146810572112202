import { DATA_TYPES } from "@constants/";
import { callAPI } from "@utils/Api";

export const updateJacket = ({ id, data }) =>
  callAPI("data/update_attributes", "POST", {
    type: DATA_TYPES.JACKET,
    id,
    attributes: [data],
  });

export const getListData = (data) => callAPI("data/list", "POST", data);

export const getJacketData = (jacketId) =>
  callAPI("data/retrieve", "POST", {
    id: jacketId,
    type: DATA_TYPES.JACKET,
  });

export const createJacket = (data) =>
  callAPI(
    "v1/jacket",
    "POST",
    data,
    undefined,
    window.ENV.REACT_APP_AUCTION_URL
  );

export const selectVehicle = ({ id, auctionId, selectedVin, total }) =>
  callAPI(
    "v1/jacket/select-vehicle",
    "POST",
    {
      jacket_id: id,
      auction_id: auctionId,
      selected_vin: selectedVin,
      total_amount: total,
    },
    undefined,
    window.ENV.REACT_APP_AUCTION_URL
  );
