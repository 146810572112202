import { TOGGLE_AUTH_MODAL } from "../Actions/ActionTypes";

const initialState = {
  name: "",
};

const AuthModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AUTH_MODAL:
      return {
        name: action.payload.name,
        onSuccess: action.payload.onSuccess,
      };

    default:
      return state;
  }
};

export default AuthModalReducer;
