import { get, has, trim } from "lodash";

/**
 *
 * @param {*} address
 * @returns
 */
const getAddress = (address) => ({
  streetAddress: trim(get(address, "streetAddress.streetName", "")),
  city: get(address, "city", ""),
  state: get(address, "state", ""),
  zip: get(address, "zip", ""),
});

/**
 *
 * @param {*} user
 * @param {*} auction
 * @returns
 */
export const getPersonalDetails = (user, auction) => {
  const hasPersonalInfoInAuction = isStepCompleted(auction, "personal_details");
  let userDetails = {};
  // if auction object has personal details, then set it, otherwise, get details from the profile page.
  if (hasPersonalInfoInAuction) {
    const personalDetails = get(auction, "payload.personal_details");
    userDetails = {
      fullName: get(personalDetails, "full_name", ""),
      phone: get(personalDetails, "phone", ""),
      creditScore: get(personalDetails, "credit_score", ""),
      shipping: {
        streetAddress: get(personalDetails, "shipping.street_address", ""),
        city: get(personalDetails, "shipping.city", ""),
        state: get(personalDetails, "shipping.state", ""),
        zip: get(personalDetails, "shipping.zip", ""),
      },
      billing: {
        streetAddress: get(personalDetails, "billing.street_address", ""),
        city: get(personalDetails, "billing.city", ""),
        state: get(personalDetails, "billing.state", ""),
        zip: get(personalDetails, "billing.zip", ""),
      },
      paymentMethod: get(personalDetails, "payment_method", "loan"),
    };
  } else {
    const userPayload = get(user, "payload");
    const shippingAddress = get(user, "payload.shipping");
    const billingAddress = get(user, "payload.billing");
    const name = get(userPayload, "name");
    const fullName =
      name ||
      [get(userPayload, "firstName", ""), get(userPayload, "lastName", "")]
        .filter(Boolean)
        .join(" ");

    userDetails = {
      fullName,
      phone: get(shippingAddress, "phone", ""),
      shipping: getAddress(shippingAddress),
      billing: getAddress(billingAddress),
      paymentMethod: "loan",
    };
  }

  return userDetails;
};

export const getPreQualifyDetails = (user) => {
  const preQualifyDetails = get(user, "payload.prequalify");

  let preQualifyObject = {};
  if (Boolean(preQualifyDetails)) {
    preQualifyObject = {
      name: get(preQualifyDetails, "name", ""),
      address: get(preQualifyDetails, "address", ""),
      city: get(preQualifyDetails, "city", ""),
      state: get(preQualifyDetails, "state"),
      postalCode: get(preQualifyDetails, "postal_code"),
      ssn: get(preQualifyDetails, "ssn", ""),
      score: get(preQualifyDetails, "score", ""),
      scorecard_name: get(preQualifyDetails, "scorecard_name", ""),
      tier: get(preQualifyDetails, "tier", ""),
      requested: get(preQualifyDetails, "requested", ""),
    };
  } else {
    const userPayload = get(user, "payload");
    const shippingAddress = getAddress(get(user, "payload.shipping"));

    preQualifyObject = {
      name: [
        get(userPayload, "firstName", ""),
        get(userPayload, "lastName", ""),
      ]
        .filter(Boolean)
        .join(" ")
        .trim(),
      address: shippingAddress.streetAddress,
      city: shippingAddress.city,
      state: shippingAddress.state,
      postalCode: shippingAddress.zip,
      ssn: get(userPayload, "ssn", ""),
      requested: Date.now(),
    };
  }

  return preQualifyObject;
};
/**
 *
 * @param {*} auction
 * @param {*} property
 * @returns
 */
export const isStepCompleted = (auction, property) => {
  return has(auction, `payload.${property}`);
};
