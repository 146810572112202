import React from "react";
import CheckboxGroup from "./CheckboxGroup";
import Input from "./Input";
import Radio from "./Radio";
import Slider from "./Slider";
import Textarea from "./Textarea";
import ThemedInput from "./ThemedInput";

const Types = {
  INPUT: "input",
  RADIO: "radio",
  SELECT: "select",
  SLIDER: "slider",
  THEMED: "themed",
  CHECKBOX: "checkbox",
  TEXTAREA: "textarea",
};

const InputRender = {
  [Types.INPUT]: (props) => <Input {...props} />,
  [Types.RADIO]: (props) => <Radio {...props} />,
  [Types.SELECT]: (props) => <ThemedInput type="select" {...props} />,
  [Types.SLIDER]: (props) => <Slider {...props} />,
  [Types.TEXTAREA]: (props) => <Textarea {...props} />,
  [Types.THEMED]: (props) => <ThemedInput {...props} />,
  [Types.CHECKBOX]: (props) => <CheckboxGroup {...props} />,
};

const FormController = ({ control, ...rest }) => InputRender[control](rest);

export default FormController;
