import { COMPANY_NAME } from "@constants/";
import { lazy } from "react";
import { Redirect } from "react-router";
import Main from "src/Layout/Main";
import ContactRouter from "../Layout/Contact";
import Home from "@pages/Home";
const ByvRouterOutlet = lazy(() => import("@pages/BuildYourVehicle/ByvRouter"));
const BuyerAuction = lazy(() => import("@pages/BuyerAuction"));
const BDRouterOutlet = lazy(() => import("@pages/BuyerDashboard"));
const MyCompany = lazy(() => import("@pages/BuyerDashboard/MyCompany"));
const MyFinance = lazy(() => import("@pages/BuyerDashboard/MyFinance"));
const MyOrders = lazy(() => import("@pages/BuyerDashboard/MyOrders"));
const WinningBids = lazy(() => import("@pages/BuyerDashboard/WinningBids"));
const SelectOffer = lazy(() =>
  import("@pages/BuyerDashboard/WinningBids/SelectOffer")
);
const BuyerLogin = lazy(() => import("@pages/BuyerLogin"));
const BuyerSignUp = lazy(() => import("@pages/BuyerSignUp"));
const CreateAccount = lazy(() => import("@pages/CreateAccount/CreateAccount"));
const DDRouterOutlet = lazy(() => import("@pages/DealerDashboard/DDRouter"));
const DealerLogin = lazy(() => import("@pages/DealerLogin/DealerLogin"));
const RegistrationFormEdit = lazy(() =>
  import("@pages/DealerRegistrationForm/RegistrationFormEdit")
);
const RegistrationFormReview = lazy(() =>
  import("@pages/DealerRegistrationForm/RegistrationFormReview")
);
const ForgotPassword = lazy(() => import("@pages/ForgotPassword"));
const LeaseFinanceCalculator = lazy(() =>
  import("@pages/LeaseFinanceCalculator/LeaseFinanceCalculator")
);
const ProfileRegistration = lazy(() => import("@pages/ProfileRegistration"));
const BuyerResetPassword = lazy(() =>
  import("@pages/ResetPassword/BuyerResetPassword")
);
const ResetPassword = lazy(() => import("@pages/ResetPassword/ResetPassword"));
const TradeIn = lazy(() => import("@pages/AuctionProcess/TradeIn/TradeIn"));

const LiveAuction = lazy(() =>
  import("@pages/DealerDashboard/LiveAuction/LiveAuction")
);

const Summary = lazy(() => import("@pages/DealerDashboard/Summary"));

const PendingDeals = lazy(() =>
  import("@pages/DealerDashboard/PendingDeals/PendingDeals")
);
const ClosedDeal = lazy(() =>
  import("@pages/DealerDashboard/ClosedDeals/ClosedDeals")
);

const Inventory = lazy(() => import("@pages/DealerDashboard/Inventory"));

const DealSummary = lazy(() => import("@pages/DealerDashboard/DealSummary"));
const DealerOnboarding = lazy(() => import("@pages/DealerOnboarding"));
const SDRouterOutlet = lazy(() => import("@pages/SupportDashboard"));
const Dealers = lazy(() => import("@pages/SupportDashboard/Dealers"));
const SupportAuctions = lazy(() => import("@pages/SupportDashboard/Auctions"));

export const RouterRoles = [
  {
    path: "/",
    exact: true,
    component: Home,
    includeHeader: true,
    includeFooter: true,
    isAuthenticated: false,
    roles: ["buyer", "bidder"],
  },
  {
    exact: false,
    path: "/contact",
    includeHeader: true,
    includeFooter: true,
    isAuthenticated: false,
    component: ContactRouter,
    roles: ["buyer", "bidder"],
  },
  {
    exact: false,
    roles: ["buyer"],
    path: "/research",
    includeHeader: true,
    includeFooter: false,
    isAuthenticated: false,
    component: ByvRouterOutlet,
  },
  {
    path: "/auction/:id",
    exact: false,
    component: BuyerAuction,
    roles: ["buyer"],
    includeHeader: true,
    includeFooter: false,
    isAuthenticated: true,
  },
  {
    component: DDRouterOutlet,
    path: "/dealer-dashboard",
    exact: false,
    roles: ["bidder"],
    includeHeader: true,
    includeFooter: false,
    isAuthenticated: true,
    children: [
      {
        path: "/dealer-dashboard/summary",
        component: Summary,
        exact: false,
        props: {},
      },
      {
        path: "/dealer-dashboard/auctions",
        component: LiveAuction,
        exact: false,
        props: {},
      },
      {
        path: "/dealer-dashboard/pending-deals",
        component: PendingDeals,
        exact: true,
        props: {},
      },
      {
        path: "/dealer-dashboard/closed-deals",
        component: ClosedDeal,
        exact: true,
        props: {},
      },
      {
        path: "/dealer-dashboard/inventory",
        component: Inventory,
        exact: true,
        props: {},
      },
      {
        path: "/dealer-dashboard/pending-deals/:id/summary",
        component: DealSummary,
        exact: false,
        props: {
          isClosedDeal: false,
        },
      },
      {
        path: "/dealer-dashboard/closed-deals/:id/summary",
        component: DealSummary,
        exact: false,
        props: {
          isClosedDeal: true,
        },
      },
      {
        path: "/dealer-dashboard",
        component: Redirect,
        exact: true,
        props: {
          from: "/dealer-dashboard",
          to: "/dealer-dashboard/auctions",
        },
      },
    ],
  },
  {
    path: "/lease-finance-calculator",
    exact: false,
    component: LeaseFinanceCalculator,
    roles: ["buyer"],
    includeHeader: true,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    exact: false,
    includeFooter: true,
    includeHeader: true,
    isAuthenticated: true,
    roles: ["buyer", "bidder"],
    path: "/registration/edit",
    component: RegistrationFormEdit,
  },
  {
    path: "/registration/review",
    exact: false,
    component: RegistrationFormReview,
    roles: ["buyer", "bidder"],
    includeHeader: true,
    includeFooter: true,
    isAuthenticated: true,
  },
  {
    path: "/dealer/signup",
    exact: false,
    component: CreateAccount,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    path: "/dealer/login",
    exact: false,
    component: DealerLogin,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    path: "/dealer/onboarding",
    exact: false,
    component: DealerOnboarding,
    roles: [],
    includeHeader: true,
    includeFooter: true,
    isAuthenticated: false,
  },
  {
    path: "/buyer/reset-password",
    exact: false,
    component: BuyerResetPassword,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    path: "/reset-password",
    exact: false,
    component: ResetPassword,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    path: "/buyer/login",
    exact: false,
    component: BuyerLogin,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    path: "/profile/registration",
    exact: false,
    component: ProfileRegistration,
    roles: [],
    includeHeader: true,
    includeFooter: true,
    isAuthenticated: true,
  },
  {
    roles: [],
    exact: false,
    includeFooter: false,
    includeHeader: false,
    path: "/buyer/signup",
    component: BuyerSignUp,
    isAuthenticated: false,
  },
  {
    path: "/buyer/forgot-password",
    exact: false,
    component: ForgotPassword,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    path: "/dealer/forgot-password",
    exact: false,
    component: ForgotPassword,
    roles: [],
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    roles: [],
    exact: false,
    path: "/trade-in",
    component: TradeIn,
    includeHeader: false,
    includeFooter: false,
    isAuthenticated: false,
  },
  {
    exact: false,
    component: Main,
    includeFooter: true,
    includeHeader: true,
    isAuthenticated: true,
    path: "/process/:id",
    roles: ["buyer", "bidder"],
  },
  {
    exact: false,
    component: BDRouterOutlet,
    includeFooter: true,
    includeHeader: true,
    isAuthenticated: true,
    path: "/dashboard",
    roles: ["buyer"],
    children: [
      {
        path: `/dashboard/my-${COMPANY_NAME}`.toLowerCase(),
        component: MyCompany,
        exact: false,
        props: {},
      },
      {
        path: "/dashboard/winning-bids/:auction?",
        component: WinningBids,
        exact: true,
        props: {},
      },
      {
        path: "/dashboard/orders/:jacketId?",
        component: MyOrders,
        exact: true,
        props: {},
      },
      {
        path: "/dashboard/offers/:auction/:vehicle",
        component: SelectOffer,
        exact: true,
        props: {},
      },

      {
        path: "/dashboard/financing",
        component: MyFinance,
        exact: true,
        props: {},
      },
      {
        path: "/dashboard",
        component: Redirect,
        exact: true,
        props: {
          from: "/dashboard",
          to: `/dashboard/my-${COMPANY_NAME}`.toLowerCase(),
        },
      },
    ],
  },
  {
    exact: false,
    component: SDRouterOutlet,
    includeFooter: true,
    includeHeader: true,
    isAuthenticated: false,
    path: "/support-dashboard",
    roles: [], //TODO: add support here
    children: [
      {
        path: "/support-dashboard/dealers",
        component: Dealers,
        exact: false,
        props: {},
      },
      {
        path: "/support-dashboard/auctions",
        component: SupportAuctions,
        exact: false,
        props: {},
      },
      {
        path: "/support-dashboard",
        component: Redirect,
        exact: true,
        props: {
          from: "/support-dashboard",
          to: "/support-dashboard/dealers",
        },
      },
    ],
  },
];
