import Button from "@components/Button";
import ThemeInput from "@components/ThemeInput";
import { DEFAULT_ERROR_MESSAGE } from "@constants/index";
import { ForgotPasswordValidation } from "@utils/validations/Auth";
import { isYupError, parseYupError } from "@utils/Yup";
import classNames from "classnames";
import { get } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { toggleAuthModal } from "src/redux/Actions/Auth";
import { SHOW_TOAST } from "src/redux/Actions/ToastActions";
import { callAPI } from "src/utils/Api";
import styles from "../styles.module.scss";

const RenderForm = () => {
  const onSuccess = useSelector((state) => state.authModalReducer.onSuccess);

  const [form, setForm] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: "",
  });
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await ForgotPasswordValidation.validate(form, {
        abortEarly: false,
      });
      await callAPI("user/buyer/forgot-password", "POST", {
        email: form.email,
      });
      dispatch({
        type: SHOW_TOAST,
        payload: {
          type: "success",
          message:
            "A link to reset your password has been sent to your email successfully.",
        },
      });
      dispatch(toggleAuthModal(""));
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        const message = get(
          error,
          "response.data.message",
          DEFAULT_ERROR_MESSAGE
        );
        dispatch({
          type: SHOW_TOAST,
          payload: {
            type: "error",
            message,
          },
        });
      }
    }

    setIsLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form}>
        <Row className="my-4">
          <Col lg={12} sm={12}>
            <ThemeInput
              type="text"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Email Address"
              label="Email Address"
              showRequiredMark
              error={errors.email}
            />
            <Button
              type="submit"
              size="sm"
              isLoading={isLoading}
              variant="secondary"
              block
              className="my-4"
            >
              SEND
            </Button>
          </Col>
          <span
            className={classNames(
              "text-center cursor-pointer",
              styles.textBlue
            )}
            onClick={() => dispatch(toggleAuthModal("signin", onSuccess))}
          >
            Back to Sign In
          </span>
        </Row>
      </form>
    </>
  );
};

export default RenderForm;
