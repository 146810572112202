import { ErrorMessage, Field } from "formik";
import React from "react";
import InputErr from "./InputErr";

const Textarea = ({ label, name, ...rest }) => (
  <div className="form-group mb-4">
    {!!label && (
      <label className="text-simple" htmlFor={name}>
        {label}
      </label>
    )}
    <Field as="textarea" id={name} name={name} {...rest} />
    <ErrorMessage name={name} component={InputErr} />
  </div>
);

export default Textarea;
