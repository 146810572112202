import { COMPANY_FIRSTNAME, COMPANY_LASTNAME, COMPANY_NAME } from "@constants/";
import classNames from "classnames";
import React from "react";
import styles from "./styles.module.scss";

const CompanyName = ({
  showInc,
  bold = true,
  className,
  showTM,
  showMultiColored = false,
  showApostropheS = false,
}) => (
  <span
    className={classNames(
      styles.companyName,
      {
        [styles.bold]: bold,
      },
      className
    )}
  >
    {showMultiColored ? (
      <>
        <span className={styles.firstName}>{COMPANY_FIRSTNAME}</span>
        {COMPANY_LASTNAME}
      </>
    ) : (
      <>{COMPANY_NAME}</>
    )}
    {showTM && <sup>TM</sup>}
    {showInc && ", Inc."}
    {showApostropheS && "'s "}
  </span>
);

export default CompanyName;
