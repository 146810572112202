import classNames from "classnames";
import { snakeCase } from "lodash";
import React from "react";
import { Spinner } from "reactstrap";
import Space from "../Space";
import styles from "./styles.module.scss";

const Button = ({
  filled,
  outline,
  size = "lg",
  className,
  children,
  isLoading,
  disabled,
  bordered = true,
  fillColor = "blue",
  variant,
  block,
  icon,
  ...props
}) => {
  const ContainerComponent = isLoading || icon ? Space : React.Fragment;
  return (
    <button
      className={classNames(
        className,
        "btn-outline",
        styles.button,
        styles[`btn-${size}`],
        {
          [styles.primary]: variant === "primary",
          [styles.secondary]: variant === "secondary",
          [styles.outline]: outline,
          [styles.filled]: filled,
          [styles.bordered]: bordered,
          [styles.green]: fillColor === "green",
          "w-100": block,
        }
      )}
      id={`${snakeCase(children)}`}
      {...props}
      disabled={disabled || isLoading}
    >
      <ContainerComponent
        {...(isLoading || icon ? { classes: "justify-content-center" } : {})}
      >
        {isLoading ? <Spinner size={size} /> : icon}
        {children}
      </ContainerComponent>
    </button>
  );
};

export default Button;
