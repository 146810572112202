import React from "react";
import styles from "./styles.module.scss";

const Terms = () => {
  return (
    <a
      href="https://www.bidwizer.com/dealer-services-agreement/"
      target="_blank"
      rel="noreferrer"
      className={styles.terms}
      id="termsConditions_"
    >
      Terms and Conditions
    </a>
  );
};

export default Terms;
