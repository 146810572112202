import { DATA_TYPES } from "@constants/";
import { callAPI } from "@utils/Api";

export const getVehicleConfiguration = (id) =>
  callAPI("data/retrieve", "POST", {
    type: DATA_TYPES.VEHICLE_CONFIGURATION,
    id,
  });

export const updateVehicleConfigurationAttributes = ({ id, data }) =>
  callAPI("data/update_attributes", "POST", {
    type: DATA_TYPES.VEHICLE_CONFIGURATION,
    id,
    attributes: [data],
  });

export const updateSelectedVehicles = ({ userId, index, type, id, vehicles }) =>
  callAPI("vehicle/configuration/update", "POST", {
    token: window.ENV.REACT_APP_ACTUAL_IMAGES_TOKEN,
    parent: userId,
    index,
    type,
    id,
    selected_updates: vehicles,
  });
