import {
  getDBRates,
  getDeliveryQuote,
  getFinancialProductsList,
  getIncentives,
  getVendorIncentives,
} from "@services/financials";
import { useQuery } from "react-query";

export const useDBRates = () =>
  useQuery(["db_rates"], () => getDBRates(), {
    refetchInterval: 0,
    refetchIntervalInBackground: 0,
    cacheTime: 10000000,
  });

export const useIncentives = (filter, options = {}) =>
  useQuery(["incentives", { ...filter }], () => getIncentives(filter), options);

export const useVendorIncentives = (filter, options = {}) =>
  useQuery(
    ["vendor-incentives", { ...filter }],
    () => getVendorIncentives(filter),
    options
  );

export const useFinancialProducts = (data, options) =>
  useQuery(
    ["financial_products_list", { ...data }],
    () => getFinancialProductsList(data),
    {
      refetchOnWindowFocus: true,
      ...options,
    }
  );
export const useDeliveryQuote = (data, options) =>
  useQuery(
    [`delivery-quote-${data.pickup}`, { ...data }],
    () => getDeliveryQuote(data),
    options
  );
