import { Form, Formik } from "formik";
import React from "react";
import "./style.scss";

const FormContainer = ({
  onSubmit,
  children,
  initialValues,
  validationSchema,
}) => (
  <div className="formik-form-container">
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {(formik) => <Form>{children}</Form>}
    </Formik>
  </div>
);

export default FormContainer;
