import { useToggle } from "@hooks/useToggle";
import React from "react";
import CookiePopUp from ".";

const CookieModal = () => {
  const cookieAccepted = JSON.parse(localStorage.getItem("cookieAccept"));

  const [isCookieModalOpen, toggleCookieModal] = useToggle(
    cookieAccepted === null
  );

  return isCookieModalOpen && <CookiePopUp toggle={toggleCookieModal} />;
};

export default CookieModal;
