import Spinner from "@assets/Images/bw-spinner.svg";
import Backdrop from "@material-ui/core/Backdrop";
import React from "react";
import styles from "./style.module.scss";

const Loader = ({ isLoading }) => (
  <Backdrop
    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={isLoading ? isLoading : false}
  >
    <object type="image/svg+xml" data={Spinner} className={styles.spinner}>
      svg-animation
    </object>
  </Backdrop>
);
export default Loader;
