import { trimStart } from "lodash-es";
import QueryString from "qs";
import { useLocation as useNativeLocation } from "react-router-dom";

export const useLocation = () => {
  const location = useNativeLocation();

  return {
    ...location,
    query: QueryString.parse(trimStart(location.search, "?")),
  };
};
