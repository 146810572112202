import DrawerToggle from "@components/DrawerToggle";
import { useToggle } from "@hooks/useToggle";
import ContactSidebar from "@pages/Contact/shared/ContactSidebar";
import classNames from "classnames";
import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Card, Col, Row } from "reactstrap";
import styles from "./styles.module.scss";

const ContactHome = lazy(() => import("@pages/Contact/Home"));
const Dealership = lazy(() => import("@pages/Contact/Dealership"));
const Partnership = lazy(() => import("@pages/Contact/Partnership"));

const ContactRouter = () => (
  <Switch>
    <Suspense fallback={<div>Loading...</div>}>
      <Route exact component={ContactHome} path={`/contact`} />
      <Route
        exact
        component={Dealership}
        path={`/contact/dealership-information`}
      />
      <Route
        exact
        component={Partnership}
        path={`/contact/partnership-information`}
      />
    </Suspense>
  </Switch>
);

const ContactLayout = () => {
  const [isOpen, togglePopUp] = useToggle();

  return (
    <main className="gradient-bg-reverse mt-5 pt-2">
      <div className={styles.container}>
        <Row
          className={classNames(
            "px-0",
            "g-0",
            "overflow-hidden",
            "position-relative"
          )}
        >
          <Col
            xl={9}
            lg={9}
            className={classNames("p-0", styles.contactProcessCol)}
          >
            <Card className={classNames(styles.contactForm, "p-4")}>
              <ContactRouter />
            </Card>
          </Col>
          {isOpen && <Col onClick={togglePopUp} className={styles.overlay} />}
          <aside
            className={classNames(
              "p-0",
              "m-0",
              "col-lg-3",
              "col-xl-3",
              "mobile-drawer",
              {
                open: isOpen,
              }
            )}
          >
            <Card className={styles.contactProcess}>
              <DrawerToggle isOpen={isOpen} handleToggle={togglePopUp} />
              <ContactSidebar />
            </Card>
          </aside>
        </Row>
      </div>
    </main>
  );
};

export default ContactLayout;
