import classNames from "classnames";
import { snakeCase } from "lodash";
import React, { useState } from "react";
import { Collapse as NativeCollapse } from "reactstrap";
import styles from "./styles.module.scss";

const Collapse = ({
  isDefaultOpen,
  title,
  children,
  primaryColoredTitle,
  size = "md",
  expandIcon,
  collapseIcon,
}) => {
  const [isVisible, setIsVisible] = useState(isDefaultOpen);

  return (
    <>
      <h6
        className="text-center mb-2 cursor-pointer"
        onClick={() => setIsVisible((prev) => !prev)}
        id={`${snakeCase(title)}`}
      >
        <div className="d-flex justify-content-between">
          <span
            className={classNames(styles[`size-${size}`], {
              [styles.title]: primaryColoredTitle,
            })}
          >
            {title}
          </span>
          <span>
            {isVisible && (
              <>
                {expandIcon ? (
                  <img src={expandIcon} alt="icon" />
                ) : (
                  <i className="fa fa-angle-down" aria-hidden="true" />
                )}
              </>
            )}
            {!isVisible && (
              <>
                {collapseIcon ? (
                  <img src={collapseIcon} alt="icon" />
                ) : (
                  <i className="fa fa-angle-up" aria-hidden="true" />
                )}
              </>
            )}
          </span>
        </div>
      </h6>
      <NativeCollapse isOpen={isVisible}>{children}</NativeCollapse>
    </>
  );
};

export default Collapse;
