import AppInput from "@components/ThemeInput";
import { Field } from "formik";
import React from "react";

const ThemeInput = ({ name, type = "text", label, ...rest }) => {
  return (
    <Field id={name} name={name} {...rest}>
      {({ field, form: { errors } }) => (
        <AppInput label={label} type={type} error={errors[name]} {...field} />
      )}
    </Field>
  );
};

export default ThemeInput;
