import Modal from "@components/Modal";
import useAuth from "@hooks/useAuth";
import { useLocation } from "@hooks/useLocation";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { INACTIVITY_TIME_IN_SECONDS } from "./constants";

const NoActivityTimer = () => {
  const { isAuthenticated, logout } = useAuth();
  const [showExpirationModal, setShowExpirationModal] = useState(false);
  const location = useLocation();
  const shouldLogout = !location.pathname.startsWith("/auction/");
  const history = useHistory();

  const { reset } = useIdleTimer({
    timeout: INACTIVITY_TIME_IN_SECONDS * 1000,
    onIdle: () => {
      if (isAuthenticated && shouldLogout) {
        setShowExpirationModal(true);
      }
      reset();
    },
  });

  useEffect(() => {
    reset();
  }, [reset, location.pathname]);

  const onLogout = () => {
    logout(() => {
      history.push("/");
    });
  };
  const closeModal = () => {
    setShowExpirationModal(false);
    onLogout();
  };
  return (
    <Modal
      isOpen={showExpirationModal}
      toggle={closeModal}
      centered
      title="Alert! Your session is expired!"
      footer={null}
      filledHeader
    >
      You are logged out of the system because of inactivity.
    </Modal>
  );
};

export default NoActivityTimer;
