import Slider from "@mui/material/Slider";
import { ErrorMessage, Field } from "formik";
import { snakeCase } from "lodash";
import React from "react";
import InputErr from "./InputErr";

const CustomSlider = ({
  form,
  units,
  field,
  maximum,
  minimum,
  ariaLabel = "Default",
  ...props
}) => (
  <div className="slider-container d-flex flex-column mb-3 px-2">
    <p className="slider-labels">
      Mileage:
      <b>
        {field.value} {units}
      </b>
    </p>
    <Slider
      min={minimum}
      max={maximum}
      classes={{
        root: "slider-root",
      }}
      {...props}
      {...field}
    />
    <div className="d-flex justify-content-between">
      <p className="slider-labels">{minimum}</p>
      <p className="slider-labels">{maximum}</p>
    </div>
  </div>
);

const FromSlider = ({ name, ...props }) => (
  <div>
    <Field
      component={CustomSlider}
      name={name}
      {...props}
      id={`${snakeCase(name)}`}
    />
    <ErrorMessage component={InputErr} name={name} />
  </div>
);

export default FromSlider;
