import CalculatorScreen from "@components/Calculator/CalculatorScreen";
import ThemeInput from "@components/ThemeInput";
import { LEASE_TERMS_OPTIONS, LOAN_TERMS_OPTIONS } from "@constants/index";
import { useDBRates } from "@hooks/finance";
import { useVendorPaymentQuote, useVendorVehicleDetails } from "@hooks/payment";
import first from "lodash/first";
import get from "lodash/get";
import inRange from "lodash/inRange";
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { MdClose } from "react-icons/md";

const CreditScoreDropdown = ({ value, ...props }) => {
  const { isLoading: loading, data } = useDBRates();

  const selectOptions = useMemo(() => {
    const options = get(data, "interest_rates.tiers", []);
    return options
      .filter(({ fico_high }) => fico_high > 600)
      .map(({ name, fico_low, fico_high }) => ({
        name: `${name}`,
        value: (fico_low + fico_high) / 2,
        label: `${name}: ${fico_low} - ${fico_high}`,
        fico_low,
        fico_high,
      }));
  }, [data]);

  return (
    <ThemeInput
      type="select"
      label="Select Your Estimated Credit Score"
      options={selectOptions}
      isLoading={loading}
      value={selectOptions.filter((option) =>
        inRange(value, option.fico_low, option.fico_high)
      )}
      {...props}
    />
  );
};

const NgPaymentCalculator = (
  { initialValues = {}, isLoan = true, vin, zip, mileage, msrp, extra },
  ref
) => {
  const [formValues, setFormValues] = useState(initialValues);
  const { data } = useVendorVehicleDetails({
    VIN: vin,
  });

  const cdsid = useMemo(() => {
    if (!data) {
      return;
    }
    if (!Array.isArray(data)) {
      return data.SVCQuote.CDSID;
    }
    return (
      data.find(({ MSRP }) => MSRP === String(MSRP))?.CDSID ||
      first(data)?.CDSID
    );
  }, [data, msrp]);

  console.log({ cdsid });

  const { isLoading, data: paymentData } = useVendorPaymentQuote(
    {
      VIN: vin,
      MSRP: msrp,
      ZipCode: zip,
      Odometer: mileage || 1,
      Purchase: formValues.budget,
      [isLoan ? "LoanPrice" : "LeasePrice"]: formValues.budget,
      Beacon: formValues.creditScore
        ? parseInt(formValues.creditScore)
        : undefined,
      Term: formValues.terms,
      [isLoan ? "LoanDP" : "LeaseDP"]: formValues.downPayment,
      cdsid,
    },
    {
      enabled: Boolean(vin && formValues.budget && cdsid),
    }
  );

  const [leaseQuotes, loanQuotes] = useMemo(() => {
    const laspQuotes = get(
      paymentData,
      "LASPInventoryItem.LASPQuote.Quote",
      []
    );
    const quotes = Array.isArray(laspQuotes) ? laspQuotes : [laspQuotes];
    const leaseQuotes = quotes.filter(({ Type }) => Type === "Closed End");
    const loanQuotes = quotes.filter(({ Type }) => Type === "Loan");
    return [leaseQuotes, loanQuotes];
  }, [paymentData]);

  const onChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const { terms } = formValues;

  const currentQuote = useMemo(() => {
    const leaseQuote = leaseQuotes.find(({ Term }) => Term === String(terms));
    const loanQuote = loanQuotes.find(({ Term }) => Term === String(terms));

    return isLoan ? loanQuote : leaseQuote;
  }, [isLoan, terms, leaseQuotes, loanQuotes]);

  const values = Object.values(initialValues);

  useEffect(() => {
    setFormValues(initialValues);
  }, values);

  const { MonthlyPayment } = currentQuote || {};
  useEffect(() => {
    setFormValues((prev) => ({
      ...prev,
      emi: MonthlyPayment,
    }));
  }, [MonthlyPayment]);

  useImperativeHandle(ref, () => ({
    getData: () => ({ ...currentQuote, ...formValues }),
  }));

  console.log({ formValues, paymentData, currentQuote });

  return (
    <div className="loanCalulator-dark w-60 shadow-md">
      <div className="heading px-4 py-4 d-flex justify-content-between align-items-center">
        <span>Payment Calculator</span>
        {extra}
      </div>
      <div className="d-lg-flex d-md-flex-col isInline py-2 pb-2">
        {/* vehicle budget */}
        <CalculatorScreen
          type="text"
          maxLength={11}
          name="budget"
          value={formValues.budget ?? ""}
          label="Vehicle Budget"
          id="vehicleBudget_input"
          onChange={onChange}
          className="form-control-text shadow-md"
        />
        {/* EMI */}
        <CalculatorScreen
          type="text"
          maxLength={11}
          onChange={onChange}
          id="vehicleEmi_input"
          name="emi"
          value={formValues.emi ?? ""}
          label="Estimated Monthly Payment"
          className="form-control-text shadow-md"
          loading={isLoading}
        />
      </div>
      <div className="w-100 formFieldWrapper px-3 pt-3">
        <CreditScoreDropdown
          id="selectCreditScore"
          onChange={onChange}
          value={formValues.creditScore}
          name="creditScore"
          label="Select Your Credit Score"
        />

        <ThemeInput
          className="pt-3"
          type="select"
          name="terms"
          showRequiredMark
          onChange={onChange}
          value={formValues.terms ?? 72}
          label={isLoan ? "Select Your Loan Term" : "Select Your Lease Term"}
          labelClass="form-label text-bold"
          options={isLoan ? LOAN_TERMS_OPTIONS : LEASE_TERMS_OPTIONS}
        />
        <div className="form-group pb-1">
          <ThemeInput
            prefix="$ "
            id="downpayment"
            name="downPayment"
            value={formValues.downPayment ?? "0"}
            placeholder="Please enter a number"
            label="Enter Your Down Payment"
            onChange={onChange}
            className="form-control-text form-control-text-dp "
            showRequiredMark
          />
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(NgPaymentCalculator);
