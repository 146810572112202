import ErrorBoundary from "@components/ErrorBoundary";
import Footer from "@components/Footer/Footer";
import Header from "@components/Header/Header";
import Loader from "@components/Loader";
import { useRefreshToken } from "@hooks/auth";
import useAuth from "@hooks/useAuth";
import { Alert as MuiAlert, Snackbar } from "@material-ui/core";
import { HIDE_TOAST } from "@redux/Actions/ToastActions";
import { callAPI } from "@utils/Api";
import dayjs from "dayjs";
import Cookies from "js-cookie";
import { get } from "lodash-es";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import NoActivityTimer from "../NoActivityTimer";
import AuthenticatedRoute, { UserDetailsContext } from "./AuthenticatedRoute";
import { RouterRoles } from "./RouterRole";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const RootRouterOutlet = () => {
  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMounted = useRef();
  const { isAuthenticated, refechUser } = useAuth();
  useRefreshToken();

  useEffect(() => {
    isMounted.current = true;
    if (isAuthenticated) {
      refechUser();
    }
    return () => {
      isMounted.current = false;
    };
  }, [refechUser, location.pathname, isAuthenticated]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return unlisten;
  }, [history]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({
      type: HIDE_TOAST,
    });
  };

  return (
    <>
      <Snackbar
        open={isVisible}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Switch>
        {RouterRoles.map((route) => {
          const Wrapper = route.isAuthenticated
            ? AuthenticatedRoute
            : React.Fragment;

          return (
            <Route exact={route.exact} path={route.path} key={route.path}>
              <Wrapper>
                {route.includeHeader && <Header />}
                <route.component />
                {route.includeFooter && <Footer />}
              </Wrapper>
            </Route>
          );
        })}
        <Route
          path="/status"
          render={() => (
            <>
              {JSON.stringify({
                name: "ux247",
                author: "ANTHONY D LANHAM",
                copyright: `© 2021-${dayjs().format("YYYY")}`,
                license: "SEE LICENSE IN LICENSE",
                status: "OK",
                NODE_ENV: window.ENV.REACT_APP_NODE_ENV,
                build: window.ENV.REACT_APP_BUILD,
              })}
            </>
          )}
        />
      </Switch>
    </>
  );
};

const RootRouter = () => {
  const [user, setUser] = useState({});

  const getUserDetails = useCallback(async () => {
    const loginToken = Cookies.get("loginToken");
    if (loginToken) {
      try {
        const userDetails = await callAPI("/user/profile", "GET");
        const user = get(userDetails, "_source");
        setUser({
          ...user,
          dealerId: userDetails.dealerId,
          id: userDetails._id,
        });
      } catch (error) {
        console.warn(error);
        Cookies.remove("loginToken");
        setUser({});
      }
    }
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <UserDetailsContext.Provider value={{ user, setUser, getUserDetails }}>
          <NoActivityTimer />
          <Suspense fallback={<Loader isLoading />}>
            <RootRouterOutlet />
          </Suspense>
        </UserDetailsContext.Provider>
      </Router>
    </ErrorBoundary>
  );
};

export default RootRouter;
