import { NavbarLogo } from "@assets/Images";
import useAuth from "@hooks/useAuth";
import classNames from "classnames";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "./Header.scss";
import LoggedIn from "./LoggedIn";
import NavLinksRenderer from "./NavLinksRenderer";
import NotLoggedIn from "./NotLoggeddIn";

const Header = () => {
  const { type, isAuthenticated } = useAuth();
  const { pathname } = useLocation();

  // let navElements = [
  //   { text: "Buy a Vehicle", path: "/" },
  //   { text: "Contact", path: "/contact" },
  // ];

  let navElements = [];

  if (type === "bidder" && isAuthenticated) {
    navElements = [
      { text: "Dealers Dashboard", path: "/dealer-dashboard/auctions" },
    ];
  }

  return (
    <header id="navbar" className="main fixed-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={NavbarLogo} alt="Logo" />
          </Link>
          <button
            type="button"
            aria-expanded="false"
            aria-controls="mainNav"
            data-bs-target="#mainNav"
            data-bs-toggle="collapse"
            className="navbar-toggler"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="mainNav">
            <ul className="navbar-nav ms-auto me-auto mb-2 mt-2 mb-lg-0">
              <li className="nav-item ">
                <NavLink
                  to="/"
                  id="buyHeader_"
                  exact={true}
                  className={classNames("nav-link", {
                    active:
                      pathname === "/" ||
                      pathname.includes("/research") ||
                      pathname.includes("/auction-process"),
                  })}
                >
                  Buy
                </NavLink>
              </li>
              <li className="nav-item contact">
                <NavLink
                  to="/contact"
                  id="contactRedirect_"
                  exact={true}
                  className={classNames("nav-link", "px-3", {
                    active: pathname.includes("/contact"),
                  })}
                >
                  Contact
                </NavLink>
              </li>
              <NavLinksRenderer navElements={navElements} />
            </ul>
            {isAuthenticated && <LoggedIn />}
            {!isAuthenticated && <NotLoggedIn />}
          </div>
        </div>
      </nav>
      <div className="nav-border-bottom" />
    </header>
  );
};

export default Header;
