import * as yup from "yup";

export const PhoneRegx =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const PassRegx =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{8,}$/;

const emailValidation = yup.string().email("Please enter valid email address.");

const passwordValidation = yup
  .string()
  .required("Please enter new password")
  .min(8, "Password should be at least 8 characters long.")
  .max(30, "Password should be less than 30 characters.")
  .matches(
    PassRegx,
    "Password requires at least one capital, one small letter, one number and one special character."
  );

const confirmPasswordValidation = (key) =>
  yup
    .string()
    .required("Please enter new password again.")
    .oneOf([yup.ref(key), null], "Please enter same password.");

export const SignupInitialValidation = yup.object().shape({
  firstName: yup.string().required("Please enter first name."),
  lastName: yup.string().required("Please enter last name."),
  email: emailValidation.required("Please enter email."),
  accept: yup
    .boolean()
    .required("Please accept terms and conditions.")
    .oneOf([true], "Please accept terms and conditions."),
});

export const SignupFinalValidation = yup.object().shape({
  id: yup.string().required(),
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation("password"),
  accept: yup
    .boolean()
    .required("Please accept terms and conditions.")
    .oneOf([true], "Please accept terms and conditions."),
  code: yup
    .string()
    .required("Please enter verification code.")
    .min(6, "Please enter 6 digit code.")
    .max(6, "Please enter 6 digit code."),
});

export const LoginValidation = yup.object().shape({
  email: emailValidation.required("Please enter email."),
  password: yup.string().required("Please enter new password"),
});
export const ForgotPasswordValidation = yup.object().shape({
  email: emailValidation.required("Please enter email."),
});

export const CreatePasswordValidation = yup.object().shape({
  newPassword: passwordValidation,
  confirmNewPassword: confirmPasswordValidation("newPassword"),
});

export const RepresentativeValidation = yup.object().shape({
  name: yup.string().required("Please enter full name."),
  businessTitle: yup.string().required("Please enter business title."),
  email: emailValidation.required("Please enter email."),
  phone: yup.string().required("Please enter phone number."),
  mobile: yup.string().required("Please enter mobile number."),
  extension: yup
    .string()
    .required("Please enter extension.")
    .max(4, "4 character maximum."),
});

export const RegistrationFormValidation = yup.object().shape({
  name: yup.string().required("Please enter business name."),
  url: yup.string().required("Please enter website url."),
  email: yup.string().required("Please enter company email address."),
  phone: yup.string().required("Please enter office phone number."),
  street: yup.string().required("Please enter address."),
  city: yup.string().required("Please enter city name."),
  state: yup.string().required("Please select state."),
  zip: yup
    .string()
    .required("Please enter zip code.")
    .min(5, "5 character minimum.")
    .max(5, "5 character maximum."),
});

export const BuyerSignupValidation = yup.object().shape({
  fullName: yup.string().required("Please enter full name."),
  email: emailValidation.required("Please enter email."),
  accept: yup
    .boolean()
    .required("Please accept terms and conditions.")
    .oneOf([true], "Please accept terms and conditions."),
  password: passwordValidation,
  confirmPassword: confirmPasswordValidation("password"),
  phone: yup
    .string()
    .required("Please enter phone number.")
    .length(10, "Phone number should be 10 number long.")
    .matches(PhoneRegx, "Phone number is not valid"),
});

export const ResetPasswordValidation = yup.object().shape({
  newPassword: passwordValidation,
  confirmNewPassword: confirmPasswordValidation("newPassword"),
  accept: yup
    .boolean()
    .required("Please accept terms and conditions.")
    .oneOf([true], "Please accept terms and conditions."),
});

export const BuyerForgotPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please enter email.")
    .email("Please enter valid email address."),
});
export const MobileNumberValidation = yup.object().shape({
  mobileNumber: yup
    .string()
    .required("Please enter phone number.")
    .min(7, "Phone number should be at least 7 number long.")
    .max(13, "Phone number should be less than 13 number.")
    .matches(PhoneRegx, "Phone number is not valid"),
});
