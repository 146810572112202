//check and remove duplicate entry
export const uniqueArr = (existingArr, newArray) => {
  const newArr = [...existingArr, ...newArray];
  const uniq = [...new Set(newArr)];
  return uniq;
};

export const colorsCounter = (colorsData, selectedData, colorCategory) => {
  const colorState =
    colorsData &&
    colorsData.length &&
    colorsData.map((item) => {
      let filteredColors = selectedData?.filter(
        (selectedItem) =>
          selectedItem?.[colorCategory]?.toLowerCase() ===
          item.text.toLowerCase()
      );
      return {
        text: item.text,
        count: filteredColors?.length ?? 0,
        checked: item.checked ?? false,
      };
    });

  return (
    colorState &&
    colorState.length &&
    colorState.filter((item) => item.count !== 0)
  );
};

//generate unique id
export const uid = function () {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};

export const filterByCategory = (dataArr, filterArr, filterKey) => {
  if (filterArr) {
    let newInvData = [];
    const trueFilter = filterArr.filter((item) => item.checked);
    if (trueFilter.length) {
      trueFilter.forEach((item) => {
        const filtredArr = dataArr.filter(
          (dataItem) => dataItem[filterKey] === item.text
        );
        if (filtredArr.length) {
          newInvData = [...uniqueArr(newInvData, filtredArr)];
        }
      });
      return newInvData;
    } else {
      return dataArr;
    }
  }
};

export const addComma = (value = 0) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 *
 * @param {*} vehicles
 * @returns
 */
export const getMinMaxMileage = (vehicles = []) => {
  const mileageArr = vehicles.map((item) => item.mileage);
  const maxMileage = Math.max(...mileageArr);

  return [0, Math.ceil(maxMileage / 5000) * 5000];
};
