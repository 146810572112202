import {
  ACTUAL_IMAGE_REQUEST_STARTED,
  ACTUAL_IMAGE_REQUEST_SUCCEEDED,
  ACTUAL_IMAGE_REQUEST_FAILED,
} from "../Actions/ActionTypes";

const initialState = {
  data: null,
};

const CarActualImagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTUAL_IMAGE_REQUEST_STARTED:
      return {
        data: state.data
          ? [...state.data, { isLoading: true, vin: action.payload }]
          : [{ isLoading: true, vin: action.payload }],
      };

    case ACTUAL_IMAGE_REQUEST_SUCCEEDED:
      const carData = state.data.map((item) => {
        if (item.vin === action.payload.vin) {
          return { ...item, images: action.payload.res, isLoading: false };
        } else {
          return item;
        }
      });
      return {
        data: carData,
      };

    case ACTUAL_IMAGE_REQUEST_FAILED:
      const carDataErr = state.data.map((item) => {
        if (item.vin === action.payload.vin) {
          return { ...item, error: action.payload.res, isLoading: false };
        } else {
          return item;
        }
      });
      return {
        data: carDataErr,
      };
    default:
      return {
        ...state,
      };
  }
};
export default CarActualImagesReducer;
