import { CloseIcon } from "@assets/Images/SvgIcons";
import Collapse from "@components/Collapse";
import CompanyName from "@components/CompanyName";
import React from "react";
import "./styles.scss";

const Sidebar = ({ toggle }) => {
  return (
    <div className="sidebar">
      <div onClick={toggle} className="closeIcon">
        {CloseIcon}
      </div>
      <div className="pe-5">
        <Collapse size="lg" primaryColoredTitle title="Our Company">
          <div className="option ms-4">
            <div>About Us</div>
            <div>Team</div>
            <div>Employment</div>
          </div>
        </Collapse>
        <Collapse size="lg" primaryColoredTitle title="Our Partners">
          <div className="option ms-4">
            <div>About Us</div>
            <div>Team</div>
            <div>Employment</div>
          </div>
        </Collapse>
        <Collapse size="lg" primaryColoredTitle title="Our Services">
          <div className="option ms-4">
            <div>About Us</div>
            <div>Team</div>
            <div>Employment</div>
          </div>
        </Collapse>
      </div>
      <div className="pe-2">
        <div>
          <h4 className="mt-2">
            Why <CompanyName />?
          </h4>
          <p className="subText">
            <CompanyName showApostropheS />
            exclusive live online auction platform solves the biggest problems
            in the automobile market. We provide the easiest solution for you to
            personally buy or sell your car and get the best price. Completely
            online, transparent, fast, and easy to use.
          </p>
        </div>
        <h5 className="tagline text-start mt-3">The King of Deals!</h5>
      </div>
    </div>
  );
};

export default Sidebar;
