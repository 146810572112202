import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import { ErrorMessage, Field } from "formik";
import React from "react";
import InputErr from "./InputErr";

const CheckboxGroup = ({ label, name, options = [], row = false, ...rest }) => (
  <FormControl component="fieldset">
    {!!label && <FormLabel component="legend">{label}</FormLabel>}
    <RadioGroup row={row} aria-label={label} name="row-radio-buttons-group">
      <Field name={name} {...rest}>
        {({ field }) =>
          options.map((opt) => (
            <FormControlLabel
              {...field}
              {...opt}
              label={opt.key}
              control={<Checkbox />}
            />
          ))
        }
      </Field>
    </RadioGroup>
    <ErrorMessage component={InputErr} name={name} />
  </FormControl>
);

export default CheckboxGroup;
