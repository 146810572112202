import ThemeInput from "@components/ThemeInput";
import { useDBRates } from "@hooks/finance";
import { first, get, inRange, isFunction, omit } from "lodash";
import React, { useEffect } from "react";

const EstimatedCreditScoreDropdown = ({
  getTiers,
  label = "Select Your Estimated Credit Score",
  setInterestRateAsValue = false,
  useNewCarInterest = true,
  value,
  creditScore,
  score,
  setScore,
  ...props
}) => {
  const { isLoading: loading, data } = useDBRates();

  useEffect(() => {
    if (data && isFunction(getTiers)) {
      getTiers(get(data, "interest_rates.tiers", []));
    }
    // eslint-disable-next-line
  }, [data, creditScore]);

  const options = get(data, "interest_rates.tiers", []);
  const selectOptions = options.map(
    ({
      new_car_percentage,
      used_car_percentage,
      name,
      fico_low,
      fico_high,
    }) => ({
      name: `${name}`,
      value: (fico_low + fico_high) / 2,
      label: `${name}: ${fico_low} - ${fico_high}`,
      fico_low,
      fico_high,
    })
  );
  return (
    <ThemeInput
      type="select"
      isLoading={loading}
      label={label}
      options={selectOptions}
      value={selectOptions.filter((option) =>
        inRange(score, option.fico_low, option.fico_high)
      )}
      onChange={(e) =>
        props.onChange(
          e,
          options.find(({ name }) => name === first(e.target.value))
        )
      }
      {...omit(props, ["type", "children", "label", "onChange"])}
    />
  );
};

export default EstimatedCreditScoreDropdown;
