import { callAPI } from "@utils/Api";
import {
  MAKE_SELECT_BOX_DATA,
  MODEL_SELECT_BOX_DATA,
  RETURN_INITIAL,
  RETURN_MAKE,
  RETURN_MODEL,
  RETURN_SERIES,
  SAVE_SELECTION_STATE,
  SELECT_MAKE,
  SELECT_MODEL,
  SELECT_SERIES,
  SELECT_STYLE,
  SELECT_YEAR,
  SERIES_SELECT_BOX_DATA,
  SET_ALL_STEPS_DATA,
  STYLE_SELECT_BOX_DATA,
  YEAR_REQUEST_FAILED,
  YEAR_REQUEST_STARTED,
  YEAR_REQUEST_SUCCEEDED,
} from "./ActionTypes";

export const selectYear = (data) => ({
  type: SELECT_YEAR,
  payload: data,
});

export const selectMake = (data) => ({
  type: SELECT_MAKE,
  payload: data,
});

export const selectModel = (data) => ({
  type: SELECT_MODEL,
  payload: data,
});

export const selectSeries = (data) => ({
  type: SELECT_SERIES,
  payload: data,
});

export const selectStyle = (data) => ({
  type: SELECT_STYLE,
  payload: data,
});

export const eraseSelected = () => ({
  type: RETURN_INITIAL,
});

export const initialMake = () => ({
  type: RETURN_MAKE,
});

export const initialModel = () => ({
  type: RETURN_MODEL,
});

export const initialSeries = () => ({
  type: RETURN_SERIES,
});

export const setAllStepsData = (data) => ({
  type: SET_ALL_STEPS_DATA,
  payload: data,
});

const isYearPresent = (year, Arr) =>
  !!Arr.filter((item) => item.name === year).lenght;

// Action creator for fetch the car data according to the selected year
export const getYearCars = (year) => async (dispatch, getState) => {
  // Restrict the duplicate year entry
  const { StepsData } = getState();
  if (!isYearPresent(year?.toString(), StepsData.carsData.data)) {
    dispatch({
      type: YEAR_REQUEST_STARTED,
    });

    // api call
    const url = `vehicle/year`;
    const body = {
      year: year,
      drill: true,
      token: window.ENV.REACT_APP_ACTUAL_IMAGES_TOKEN,
    };

    try {
      const data = await callAPI(url, "POST", body);
      // If data successfully posted then
      if (data) {
        dispatch({
          type: YEAR_REQUEST_SUCCEEDED,
          payload: data.year_list[0],
        });
      }
    } catch (error) {
      dispatch({
        payload: error,
        type: YEAR_REQUEST_FAILED,
      });
    }
  }
};

export const fillMakeData = (data) => ({
  type: MAKE_SELECT_BOX_DATA,
  payload: data,
});

export const fillModelData = (data) => ({
  type: MODEL_SELECT_BOX_DATA,
  payload: data,
});

export const fillSeriesData = (data) => ({
  type: SERIES_SELECT_BOX_DATA,
  payload: data,
});

export const fillStyleData = (data) => ({
  type: STYLE_SELECT_BOX_DATA,
  payload: data,
});

export const saveSelectionState = () => (dispatch, getState) =>
  dispatch({
    type: SAVE_SELECTION_STATE,
    payload: {
      selectedData: getState().StepsData.selectedData,
      selectBoxData: getState().StepsData.selectBoxData,
    },
  });
