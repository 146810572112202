import Caret from "@assets/Images/icons/icon-dropdown.svg";
import classNames from "classnames";
import { find, head, snakeCase } from "lodash";
import React, { useMemo } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import Select, { components } from "react-select";
import styles from "./styles.module.scss";

const CaretDownIcon = () => {
  return <AiOutlineCaretDown className={styles.iconColorSize} />;
};
const CaretImage = () => {
  return <img src={Caret} alt="caret" className={styles.iconSvg} />;
};
const DropdownIndicator = (props) => {
  const { selectProps } = props;
  const CaretIcon = selectProps.isCaret ? CaretImage : CaretDownIcon;

  return (
    <components.DropdownIndicator {...props}>
      <CaretIcon />
    </components.DropdownIndicator>
  );
};

const style = {
  container: (base) => ({
    ...base,
    width: "100%",
    height: 44,
    paddingRight: 10,
  }),
  menu: (base) => ({
    ...base,
    margin: 0,
    fontSize: 16,
    zIndex: 999999,
  }),

  option: (base, state) => {
    return {
      ...base,
      cursor: "pointer",
      color: state.isSelected ? "#ffffff" : "#110f45",
      "&:hover": {
        borderColor: "#ffffff",
        color: "#ffffff",
        backgroundColor: "#110f45",
      },

      backgroundColor: state.isSelected ? "#110f45" : "#ffffff",
    };
  },
  control: (base, state) => ({
    ...base,
    borderStyle: "none",
    height: 44,
    cursor: "pointer",
  }),
};

const CustomSelect = (props) => {
  const onSelectChange = (value) =>
    props.onChange({ target: { ...value, name: props.name } });

  const value = useMemo(() => {
    let formValue = null;
    if (Array.isArray(props.value) && head(props.value)) {
      formValue = find(props.options, { value: props.value[0].value });
    } else if (props.value) {
      formValue = find(props.options, { value: props.value });
    }
    return formValue || null;
  }, [props.value, props.options]);

  return (
    <Select
      closeMenuOnScroll={(e) => e.target === document}
      defaultValue={props.defaultValue}
      menuPlacement={props.menuPlacement || "auto"}
      className={classNames(styles.formSelect, "react-select--inline")}
      isDisabled={props.isDisabled}
      isSearchable={props.isSearchable}
      options={props.options}
      onChange={onSelectChange}
      placeholder={props.placeholder}
      isLoading={props.isLoading}
      styles={style}
      isCaret={props.isCaret}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "white",
          neutral50: "#110f45",
          neutral80: "#110f45",
        },
        controlWidth: 100,
        controlHeight: 50,
      })}
      value={value}
      inputId={`${snakeCase(props.name)}`}
    />
  );
};

export default CustomSelect;
