import {
  GirlWithKey,
  HappyGirlInCar,
  HeaderBgImg,
  IconAwesomeCar,
  IconAwesomeCarSide,
  IconFeatherThumbsup,
  IconOpenPeople,
} from "@assets/Images";
import AuctionCarousel from "@components/AuctionCarousel/AuctionCarousel";
import CustomSnackBar from "@components/CustomSnackBar";
import Sidebar from "@components/Header/Sidebar";
import Loader from "@components/Loader";
import PaymentCalculatorWrapper from "@components/PaymentCalculatorWrapper";
import YmmSelection from "@components/YmmSelection";
import { MOBILE_SCREEN_WIDTH } from "@constants/";
import { useToggle } from "@hooks/useToggle";
import useWindowDimentions from "@hooks/useWindowDimensions";
import { get } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AuctionImagesSlider from "./AuctionImagesSlider";
import FindYourCar from "./FindYourCar";
import "./styles.scss";

// Component for header of al section
const SectionHeader = ({ heading = "", subheading = "" }) => (
  <header className="text-center">
    {heading && <h2>{heading}</h2>}
    {subheading && <h4 className="mt-4">{subheading}</h4>}
  </header>
);

const Home = () => {
  const [isOpen, togglePopUp] = useToggle();
  const [isScrolled, setIsScrolled] = useState(true);

  const shopByBudget = useRef(null);
  const ymmSection = useRef(null);

  const { width } = useWindowDimentions();
  const isMobile = width <= MOBILE_SCREEN_WIDTH;

  const { carsData } = useSelector((state) => state.StepsData);

  //config data
  const config = useSelector((state) => state.Configuration);

  //years make model cars data
  const ymmCarsData = useSelector((state) => state.YmmCarsData);

  const configErrorMessage = get(config, "error.response.data.message");
  const carErrorMessage = get(carsData, "error.response.data.message");

  useEffect(() => {
    function onScrollEventListener() {
      const navbar = document.getElementById("navbar");
      const navbarHeight = navbar.clientHeight;
      const bannerArea = document.getElementById("banner-area");
      const bannerAreaHeight = bannerArea.clientHeight;

      if (
        window.pageYOffset >
        bannerAreaHeight - ymmSection.current.clientHeight
      ) {
        ymmSection.current.classList.add("sticky");
        ymmSection.current.style.top = `${navbarHeight}px`;
        setIsScrolled(false);
      } else {
        ymmSection.current.classList.remove("sticky");
        ymmSection.current.style.top = "auto";
        setIsScrolled(true);
      }

      if (window.pageYOffset > 350) setIsScrolled(false);
    }
    window.addEventListener("scroll", onScrollEventListener);

    return () => window.removeEventListener("scroll", onScrollEventListener);
  }, []);

  return (
    <main className="home-page mt-5 pt-2">
      {isOpen && (
        <div className="sidebar">
          <Sidebar toggle={togglePopUp} />
        </div>
      )}
      <div id="banner-area" className="banner-area">
        <img className="bannerImage" alt="BidWizer" src={HeaderBgImg} />
        <div className="image-text">
          <div>
            <h1>For Buyers</h1>
            <h2 className="banner-title">Find cars you like</h2>
            <h2 className="banner-title">Enter cars into auction</h2>
            <h2 className="mb-4 banner-title">Dealers bid down the price </h2>
            <h1 className="banner-tagline">This Bid's For You.</h1>
          </div>
        </div>
        <div
          ref={ymmSection}
          className="ymmsection d-flex justify-content-center"
        >
          <YmmSelection
            menuPlacement={isMobile ? "bottom" : isScrolled ? "top" : "bottom"}
            showStartHereText
          />
        </div>

        <Loader
          isLoading={
            carsData.isLoading || config.isLoading || ymmCarsData.isLoading
          }
        />
        {carErrorMessage && (
          <CustomSnackBar type="error" text={carErrorMessage} />
        )}
        {configErrorMessage && (
          <CustomSnackBar type="error" text={configErrorMessage} />
        )}
      </div>

      <section className="payment-calculator" ref={shopByBudget}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-center mt-4">
              <img
                src={GirlWithKey}
                className="img-fluid  mx-auto"
                alt="Payment Calculator"
              />
              <div className="find-budget mx-auto justify-content-center d-flex">
                <div className="d-lg-flex gap-2">
                  <span className="col-lg-6 col-sm-12">
                    <h2 className="mt-5 text-lg-start">Payment Calculator</h2>
                    <h6 className="mt-3 text-lg-start">
                      Find Your Vehicle Budget
                    </h6>
                    <div className="d-lg-flex mt-4 justify-content-left"></div>
                  </span>
                  <span className="col-lg-6 col-xs-12">
                    <h5 className="mt-4 mt-md-5 text-start text-uppercase">
                      Easy to use Directions
                    </h5>
                    <ul className="mt-3 text-start">
                      <li>Enter your total budget amount</li>
                      <li>Enter your monthly payment</li>
                      <li>Select your FICO</li>
                      <li>Set your monthly terms</li>
                      <li>Change your down payment</li>
                    </ul>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12">
              <div className="payment-calculator-area">
                <PaymentCalculatorWrapper />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="carinventory v-shape-bg">
        <div className="container">
          <SectionHeader
            heading="Millions of Cars in Inventory"
            subheading="Find Your Perfect Car"
          />
          <FindYourCar />
          <h1>This Bid's For You.</h1>
        </div>
      </section>

      <section className="vehicle-live-auction">
        <div className="container">
          <SectionHeader heading="Current Live Auctions" />
          <div className="row mb-md-5 mb-1">
            <div className="col-lg-3">
              <div className="vehicles-auction-count">
                <img alt="count" src={IconAwesomeCar} />
                <h1>1,296</h1> <p>Vehicles in Auctions</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="vehicles-auction-count">
                <img alt="count" src={IconAwesomeCarSide} />
                <h1>254,080</h1> <p>Pre-owned Qualified Vehicles</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="vehicles-auction-count">
                <img alt="count" src={IconFeatherThumbsup} />
                <h1>78,682</h1> <p>Vehicles Sold</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="vehicles-auction-count">
                <img alt="count" src={IconOpenPeople} />
                <h1>32,439</h1> <p>Active Dealers</p>
              </div>
            </div>
          </div>
        </div>
        <h5 className="text-center"> Click to See Online Auctions LIVE </h5>

        <AuctionCarousel />
        <div className="w-100 d-flex justify-content-center pt-5"></div>
        <h3 className="text-center mt-4">This Bid's For You.</h3>
      </section>

      <section className="girlInCar">
        <div className="row">
          <div className="col-lg-6 text-center h-100 p-0">
            <div className="mx-auto cartext pt-2 gap-5">
              <h2 className="pt-5 text-center w-100">We Have Your Back</h2>
              <div className="d-flex justify-content-center">
                <ul className="mt-3 text-start">
                  <li>No more dealership haggling on price</li>
                  <li>No more countless hours listing your vehicle</li>
                  <li>No more strangers at the door</li>
                  <li>No more compromise on lowball trade-in's</li>
                  <li>No more waiting days or weeks for your car to sell</li>
                </ul>
              </div>
              <div className="w-100 d-flex justify-content-center mt-5"></div>
            </div>
          </div>
          <div className="col-lg-6 p-0">
            <img src={HappyGirlInCar} className="img-fluid w-100" alt="car" />
          </div>
        </div>
      </section>
      <section className="mobilesCarousel">
        <div className="container">
          <SectionHeader heading="Live Mobile Auctions" />

          <AuctionImagesSlider />

          <div className="w-100 d-flex justify-content-center mt-5"></div>
          <h1 className="text-center mt-4">This Bid's For You.</h1>
        </div>
      </section>
    </main>
  );
};

export default Home;
