import Button from "@components/Button";
import PaymentCalculatorWrapper from "@components/PaymentCalculatorWrapper";
import SideStepper from "@components/SideStepper";
import {
  CAR_TYPE,
  DEFAULT_DOWN_PAYMENT,
  DEFAULT_LOAN_TERMS,
  HIDE_PREQUALIFY,
} from "@constants/";
import { useLocation } from "@hooks/useLocation";
import { setActivePage } from "@redux/Actions/SideScrollActions";
import { isStepCompleted } from "@utils/auction-process";
import { getAveragePrice } from "@utils/generic";
import { last, uniq, uniqBy, without } from "lodash";
import get from "lodash/get";
import React, { useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal } from "reactstrap";
import { JacketDetailsContext } from "src/Layout/Main";
import "./styles.scss";
import NgPaymentCalculator from "@components/NgPaymentCalculator";
import { MdClose } from "react-icons/md";
import { getCreditScore } from "@utils/credit-score";
import useAuth from "@hooks/useAuth";

const StepsLocal = [
  {
    visited: false,
    title: "Personal Details",
    subtitle: "About 30 seconds",
    property: "personal_details",
    path: "/personal-details",
  },
  {
    visited: false,
    path: "/incentives",
    title: "Vehicle Incentives",
    subtitle: "About 2 minutes",
    property: "incentive",
    requires: ["personal_details"],
  },
  {
    visited: false,
    path: "/insurance",
    title: "F&I Products",
    subtitle: "1-3 minutes",
    property: "financial_products",
    requires: ["personal_details", "incentive"],
  },
  {
    visited: false,
    path: "/trade-in",
    title: "Vehicle Trade-In",
    subtitle: "3 minutes",
    property: "trade_in",
    requires: ["personal_details", "incentive", "financial_products"],
  },
  {
    visited: false,
    title: "Start Auction",
    path: "/start-auction",
    subtitle: "",
    requires: [
      "personal_details",
      "incentive",
      "trade_in",
      "financial_products",
    ],
  },
];

/**
 *
 * @param {*} carType
 * @returns
 */
export const getSidebarOptions = (
  carType = CAR_TYPE.NEW,
  paymentMethod,
  payoffStatus = ""
) => {
  if (!HIDE_PREQUALIFY()) {
    StepsLocal.splice(4, 0, {
      visited: false,

      title: "Pre-Qualify",
      path: "/pre-qualify",
      property: "pre_qualify_details",
      subtitle: "Less than 1 minute",
      requires: [
        "personal_details",
        "incentive",
        "trade_in",
        "financial_products",
      ],
    });
    StepsLocal[StepsLocal.length - 1]["requires"].push("pre_qualify_details");
  }

  const AuctionProcessSteps = StepsLocal;

  const isUsed = carType.toLowerCase() === CAR_TYPE.USED;
  const newSteps = AuctionProcessSteps.filter((step) => {
    if (payoffStatus === "loan" && step.property === "pre_qualify_details") {
      return true;
    }
    if (paymentMethod === "cash" && step.property === "pre_qualify_details") {
      return false;
    }

    return isUsed ? step.property !== "incentives" : true;
  }).map((step) => {
    const allRequiredSteps = step.requires || [];
    let requiredSteps = allRequiredSteps;
    if (isUsed) {
      requiredSteps = without(requiredSteps, "incentives");
    }

    if (paymentMethod === "cash") {
      requiredSteps = without(requiredSteps, "pre_qualify_details");
    }

    step.requires = uniq(requiredSteps);
    // Normal condition prequalify
    if (step.property === "pre_qualify_details") {
      step.title = "Pre-Qualify";
    }
    // When paymentMethod cash and payoff status loan Trade-In Payoff
    if (
      paymentMethod === "cash" &&
      payoffStatus === "loan" &&
      step.property === "pre_qualify_details"
    ) {
      step.title = "Trade-In Payoff";
    }

    return step;
  });
  return uniqBy(newSteps, "property");
};

const AuctionProcessSidebar = () => {
  const [openPaymentCalculator, setOpenPaymentCalculator] = useState(false);
  const {
    id: jacketId,
    details,
    vehicleConfig,
    configId,
  } = useContext(JacketDetailsContext);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  // Inventory cars from auction context
  const list = get(vehicleConfig, "selected", []).filter(
    (item) => item.selected
  );
  const averageMSRP = getAveragePrice(list);

  const calculatorBudget = averageMSRP || 20000;

  const carType = useMemo(() => {
    const selectedVehicle = get(vehicleConfig, "selected", []).find(
      (item) => item.selected
    );
    return get(selectedVehicle, "stock_type", CAR_TYPE.NEW).toLowerCase();
  }, [vehicleConfig]);

  const paymentMode = useMemo(
    () =>
      get(details, "payload.personal_details.payment_method", "").toLowerCase(),
    [details]
  );

  const payoffStatus = useMemo(
    () =>
      get(details, "payload.personal_details.payoff_status", "").toLowerCase(),
    [details]
  );

  const toggleModal = () => setOpenPaymentCalculator(!openPaymentCalculator);

  const handleClick = ({ title, isCarProfile, path }) => {
    dispatch(setActivePage(title));
    history.push(
      isCarProfile
        ? `/research/vehicle-inventory/${configId}?jacketId=${jacketId}`
        : `/process/${jacketId}${path}`
    );
  };

  const loanTerms = useMemo(
    () =>
      get(details, "payload.payment_summary.loan_terms", DEFAULT_LOAN_TERMS),
    [details]
  );
  const downPayment = useMemo(
    () =>
      get(
        details,
        "payload.payment_summary.down_payment",
        DEFAULT_DOWN_PAYMENT
      ),
    [details]
  );

  const sidebarOption = useMemo(() => {
    const currentPath = last(pathname.split("/"));

    const make = get(vehicleConfig, "search.make", "");
    const model = get(vehicleConfig, "search.model", "");
    const year = get(vehicleConfig, "search.model_year", "");
    const vinNumber = get(vehicleConfig, "selected.0.vin");

    const sideOptions = getSidebarOptions(carType, paymentMode, payoffStatus);
    const options = sideOptions.map((step, index) => {
      const isFirst = index === 0;
      const allRequiredSteps = step.requires || [];

      const isEnabled =
        isFirst ||
        allRequiredSteps.every((req) => isStepCompleted(details, req));

      step.isEnabled = isEnabled;
      step.isActive = `/${currentPath}` === step.path;
      step.isCompleted = isStepCompleted(details, step.property);
      step.handleClick = () => handleClick(step);
      return step;
    });

    options.unshift({
      isEnabled: true,
      title: `${year} ${make}`,
      subtitle: model,
      imageVin: vinNumber,
      showIndicator: false,
      handleClick: () =>
        handleClick({
          isCarProfile: true,
        }),
    });
    return options;
    // eslint-disable-next-line
  }, [carType, details, vehicleConfig, paymentMode, payoffStatus]);

  const { user } = useAuth();

  const creditScore = useMemo(() => getCreditScore(user), [user]);

  return (
    <>
      <SideStepper
        title="Auction Process"
        extra={
          <Button
            variant="primary"
            size="sm"
            onClick={toggleModal}
            id="paymentCalculator_"
          >
            Payment Calculator
          </Button>
        }
        items={sidebarOption}
      />

      <Modal toggle={toggleModal} isOpen={openPaymentCalculator}>
        {list[0] && (
          <NgPaymentCalculator
            initialValues={{
              budget: calculatorBudget,
              terms: loanTerms,
              downPayment: downPayment,
              creditScore: creditScore ?? 720,
            }}
            msrp={averageMSRP}
            vin={list[0].vin}
            mileage={list[0].mileage}
            zip={list[0].dealer_zipcode}
            extra={
              <MdClose
                size={25}
                onClick={toggleModal}
                className="cursor-pointer"
              />
            }
          />
        )}
      </Modal>
    </>
  );
};

export default AuctionProcessSidebar;
