import { AuctionFinish, AuctionInProgress, AuctionStart } from "@assets/Images";
import classNames from "classnames";
import React from "react";
import SwiperCore, { EffectFade, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.css";
import styles from "./styles.module.scss";

// install Swiper modules
SwiperCore.use([Pagination, EffectFade]);

const auctionImagesDataArr = [AuctionStart, AuctionInProgress, AuctionFinish];

const AuctionImagesSlider = () => {
  const options = {
    640: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 0,
    },
  };

  return (
    <div className={styles.desktop}>
      <div className={classNames(styles.swiperContainer, "w-100")}>
        <Swiper
          direction="horizontal"
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          loop={true}
          breakpoints={options}
        >
          {auctionImagesDataArr.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="auction process" className="img-fluid" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default AuctionImagesSlider;
