import { CloseIcon } from "@assets/Images/SvgIcons";
import Button from "@components/Button";
import Space from "@components/Space";
import classNames from "classnames";
import { isNull, isUndefined } from "lodash";
import React, { Fragment } from "react";
import {
  Modal as NativeModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import styles from "./styles.module.scss";

const Modal = ({
  isOpen,
  toggle,
  title,
  children,
  footer,
  okText,
  cancelText,
  onOk,
  onCancel,
  okButtonProps = {},
  cancelButtonProps = {},
  filledHeader = false,
  noHeader,
  ...props
}) => {
  const FooterComponent = !isNull(footer) ? ModalFooter : Fragment;
  return (
    <NativeModal isOpen={isOpen} {...props} unmountOnClose toggle={toggle}>
      {!noHeader && (
        <ModalHeader
          toggle={toggle}
          className={classNames({
            [styles.filledHeader]: filledHeader,
          })}
          close={
            <span
              onClick={toggle}
              className={styles.closeIcon}
              id="modalClose_"
            >
              {CloseIcon}
            </span>
          }
        >
          {title}
        </ModalHeader>
      )}
      <ModalBody>{children}</ModalBody>
      <FooterComponent>
        {isUndefined(footer) && (
          <Space>
            <Button onClick={onCancel} {...cancelButtonProps} bordered={false}>
              {cancelText || "Cancel"}
            </Button>
            <Button bordered={false} filled onClick={onOk} {...okButtonProps}>
              {okText || "Ok"}
            </Button>
          </Space>
        )}
        {!isUndefined(footer) ? footer : null}
      </FooterComponent>
    </NativeModal>
  );
};

export default Modal;
