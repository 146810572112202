import {
  MAKE_SELECT_BOX_DATA,
  MODEL_SELECT_BOX_DATA,
  RETURN_INITIAL,
  RETURN_MAKE,
  RETURN_MODEL,
  RETURN_SERIES,
  SAVE_SELECTION_STATE,
  SELECT_MAKE,
  SELECT_MODEL,
  SELECT_SERIES,
  SELECT_STYLE,
  SELECT_YEAR,
  SERIES_SELECT_BOX_DATA,
  SET_ALL_STEPS_DATA,
  STYLE_SELECT_BOX_DATA,
  YEAR_REQUEST_FAILED,
  YEAR_REQUEST_STARTED,
  YEAR_REQUEST_SUCCEEDED,
} from "../Actions/ActionTypes";

const initialState = {
  selectedData: {
    year: "",
    make: "",
    model: "",
    series: "",
    style: null,
  },
  backupData: {
    selectedData: {
      year: "",
      make: "",
      model: "",
      series: "",
      style: null,
    },
    selectBoxData: {
      selectBoxMake: null,
      selectBoxModel: null,
      selectBoxSeries: null,
      selectBoxStyle: null,
    },
  },
  carsData: {
    data: [],
    error: null,
    isLoading: false,
  },
  selectBoxData: {
    selectBoxMake: null,
    selectBoxModel: null,
    selectBoxSeries: null,
    selectBoxStyle: null,
  },
};

const StepsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_YEAR:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          year: action.payload,
        },
      };
    case SELECT_MAKE:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          make: action.payload,
        },
      };
    case SELECT_MODEL:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          model: action.payload,
        },
      };
    case SAVE_SELECTION_STATE:
      return {
        ...state,
        backupData: action.payload,
      };
    case SELECT_SERIES:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          series: action.payload,
        },
      };
    case SELECT_STYLE:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          style: action.payload,
        },
      };
    case SET_ALL_STEPS_DATA:
      return {
        ...state,
        selectedData: {
          ...action.payload,
        },
      };
    case RETURN_INITIAL:
      // console.log("BACKUP", state.backupData);
      return {
        ...state,
        backupData: initialState.backupData,
        selectedData: !state.backupData.selectedData.year
          ? state.backupData.selectedData
          : initialState.selectedData,
        selectBoxData: !state.backupData.selectBoxData.selectBoxMake
          ? state.backupData.selectBoxData
          : initialState.selectBoxData,
      };
    case RETURN_MAKE:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          make: "",
          model: "",
          series: "",
          style: null,
        },
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxModel: null,
          selectBoxSeries: null,
          selectBoxStyle: null,
        },
      };
    case RETURN_MODEL:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          model: "",
          series: "",
          style: null,
        },
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxSeries: null,
          selectBoxStyle: null,
        },
      };
    case RETURN_SERIES:
      return {
        ...state,
        selectedData: {
          ...state.selectedData,
          series: "",
          style: null,
        },
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxStyle: null,
        },
      };
    case YEAR_REQUEST_STARTED:
      return {
        ...state,
        carsData: {
          ...state.carsData,
          isLoading: true,
        },
      };
    case YEAR_REQUEST_SUCCEEDED:
      return {
        ...state,
        carsData: {
          ...state.carsData,
          isLoading: false,
          data: [...state.carsData.data, action.payload],
        },
      };
    case YEAR_REQUEST_FAILED:
      return {
        ...state,
        carsData: {
          ...state.carsData,
          isLoading: false,
          error: action.payload,
        },
      };
    case MAKE_SELECT_BOX_DATA:
      return {
        ...state,
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxMake: action.payload,
        },
      };
    case MODEL_SELECT_BOX_DATA:
      return {
        ...state,
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxModel: action.payload,
        },
      };
    case SERIES_SELECT_BOX_DATA:
      return {
        ...state,
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxSeries: action.payload,
        },
      };
    case STYLE_SELECT_BOX_DATA:
      return {
        ...state,
        selectBoxData: {
          ...state.selectBoxData,
          selectBoxStyle: action.payload,
        },
      };
    default:
      return state;
  }
};

export default StepsReducer;
