import CompanyName from "@components/CompanyName";
import Modal from "@components/Modal";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleAuthModal } from "src/redux/Actions/Auth";
import styles from "../styles.module.scss";
import RenderForm from "./RenderForm";

const BuyerSignUpModal = () => {
  const isShow = useSelector(
    (state) => state.authModalReducer.name === "signup"
  );
  const dispatch = useDispatch();
  const onClose = () => dispatch(toggleAuthModal(""));

  return (
    <Modal
      centered
      scrollable
      isOpen={isShow}
      unmountOnClose
      toggle={onClose}
      className={styles.modalBox}
      footer={null}
      size="md"
      filledHeader
      title={
        <div className="d-block">
          <h5 className={styles.title}>Create Your Account</h5>
          <p className={styles.subHeader}>
            Fill in the information below to set up your account with{" "}
            <CompanyName showTM={false} showInc={false} bold={false} />
          </p>
        </div>
      }
    >
      <RenderForm />
    </Modal>
  );
};
export default BuyerSignUpModal;
