import React from "react";
import { CarIcon } from "../Assets/Images/SvgIcons";

const DrawerToggle = ({ isOpen, handleToggle, carIcon = false }) => (
  <div className="toggle-button" onClick={handleToggle}>
    {!isOpen && (
      <span className="me-1">
        <i className="fa fa-angle-left"></i>
      </span>
    )}
    {carIcon && CarIcon}
    {isOpen && (
      <span className="ms-1 flip-horizontally">
        <i className="fa fa-angle-left"></i>
      </span>
    )}
  </div>
);

export default DrawerToggle;
