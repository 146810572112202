import useAuth from "@hooks/useAuth";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

export const UserDetailsContext = React.createContext({});

const AuthenticatedRoute = ({ children }) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(`/`);
    }
  }, [history, isAuthenticated]);

  return <>{children}</>;
};

export default AuthenticatedRoute;
