import React from "react";
import CurrencyInput from "react-currency-input-field";

const CalculatorScreen = ({ label, onChange, value, ...rest }) => {
  const handleChange = (value) =>
    onChange({ target: { value: Number(value), ...rest } });

  return (
    <div className="vehicleBudgetWrapper px-3 pb-3">
      <label htmlFor="vehicle-budget-section">{label}</label>
      <div
        id="vehicle-budget-section"
        className="vehicleBudgetPrice text-center"
      >
        <CurrencyInput
          prefix="$"
          suffix="*"
          value={value}
          precision="0"
          onValueChange={handleChange}
          {...rest}
        />
      </div>
    </div>
  );
};

export default CalculatorScreen;
