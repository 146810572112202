import sidebarImg from "@assets/Images/contact/sidebar.jpg";
import CompanyName from "@components/CompanyName";
import classNames from "classnames";
import React from "react";
import styles from "../styles.module.scss";

const ContactSidebar = () => {
  return (
    <div className="side-panel-nav">
      <div
        className={classNames(
          "px-2",
          "d-flex",
          "side-panel-header",
          "align-items-center",
          "justify-content-between"
        )}
      >
        <h5 className={classNames("text-white", "ms-5", "ms-md-0")}>
          Corporate Address
        </h5>
      </div>
      <div>
        <img
          className={styles.imageContainer}
          src={sidebarImg}
          alt="sidebarimg"
        />
      </div>
      <div className="p-3 bg-white">
        <iframe
          className={styles.imageContainer}
          title="Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3519.8773567397425!2d-80.57564570108181!3d28.089283637176496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88de116cf0279ce7%3A0x1183ad4dc5595dc7!2s417%205th%20Ave%2C%20Indialantic%2C%20FL%2032903%2C%20USA!5e0!3m2!1sen!2sin!4v1643646734397!5m2!1sen!2sin"
        />
      </div>
      <div className="p-3 bg-white">
        <h4>
          <CompanyName showTM={false} showInc bold={false} />
        </h4>
        <p className="mt-2 text-muted">417 5th Avenue </p>
        <p className="text-muted">Indialantic, FL. 32903</p>
        <h4 className="mt-4 text-uppercase">+1(800)920-6912</h4>
      </div>
    </div>
  );
};

export default ContactSidebar;
