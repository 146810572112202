import { SET_ACTIVE_PAGE, STEP_VISITED } from "@redux/Actions/ActionTypes";

const initState = {
  profile: {
    img: "",
    title: "",
    subtitle: "",
    path: "/buy-a-vehicle/vehicle-inventory",
  },
  activePage: "Personal Details",
  auction_steps: [
    {
      visited: false,
      title: "Personal Details",
      subtitle: "About 5 minutes",
      path: "/personal-details",
    },
    {
      visited: false,
      title: "Pre-Qualify",
      path: "/pre-qualify",
      subtitle: "About 5 minutes",
    },
    {
      visited: false,
      path: "/incentives",
      title: "Vehicle Incentives",
      subtitle: "About 5 minutes",
    },
    {
      visited: false,
      path: "/insurance",
      title: "F&I Products",
      subtitle: "About 5 minutes",
    },
    {
      visited: false,
      path: "/trade-in",
      title: "Vehicle Trade-In",
      subtitle: "About 5 minutes",
    },
    {
      visited: false,
      title: "Payment Summary",
      path: "/payment-summary",
      subtitle: "About 5 minutes",
    },
    {
      visited: false,
      path: "/required-docs",
      title: "Required Documents",
      subtitle: "About 5 minutes",
    },
    {
      visited: false,
      title: "Start Auction",
      path: "/start-auction",
      subtitle: "About 5 minutes",
    },
  ],
};

const SideScrollReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: payload,
      };
    case STEP_VISITED:
      state.auction_steps.map((step) => {
        if (step.title === payload) {
          step.visited = true;
          return step;
        }
        return step;
      });
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default SideScrollReducer;
