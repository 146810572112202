import {
  getAuction,
  getAuctions,
  getAuctionsByBuyerId,
  updateAuction,
  update,
  createAuction,
} from "@services/auction";
import { useMutation, useQuery, useQueryClient } from "react-query";

export const useAuctions = (
  { search, state, dealerId, size, buyerId },
  options
) =>
  useQuery(
    ["auctions", { search, state, dealerId, size, buyerId }],
    () => getAuctions({ dealer_id: dealerId, state, size, buyer_id: buyerId }),
    options
  );

export const useAuction = (id) =>
  useQuery(["auction-details", id], () => getAuction(id), {
    enabled: Boolean(id),
  });

export const useUpdateAuctionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateAuction, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["auction-details", id]);
      queryClient.invalidateQueries(["list"]);
      queryClient.invalidateQueries(["auction-list-by-buyer-id"]);
    },
  });
};

export const useUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(update, {
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries(["auction-details", id]);
      queryClient.invalidateQueries(["list"]);
      queryClient.invalidateQueries(["auction-list-by-buyer-id"]);
    },
  });
};

export const useAuctionsByBuyerId = (buyerId) =>
  useQuery(
    ["auction-list-by-buyer-id", buyerId],
    () => getAuctionsByBuyerId(buyerId),
    {
      enabled: Boolean(buyerId),
    }
  );

/**
 *
 * @returns
 */
export const useCreateAuction = () => {
  const queryClient = useQueryClient();

  return useMutation(createAuction, {
    onSuccess: (_, { userId }) => {
      queryClient.invalidateQueries(["list"]);
      queryClient.invalidateQueries(["auction-list-by-buyer-id", userId]);
    },
  });
};
