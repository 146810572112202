import { callAPI } from "@utils/Api";
import { get } from "lodash";
import { useEffect, useState } from "react";

const useCallApi = (
  url,
  data = {},
  method = "post",
  token,
  host,
  enabled = true
) => {
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const callApi = async () => {
    try {
      const response = await callAPI(url, method, data, token, host);
      setResponseData(get(response, "data", response));
    } catch (err) {
      setError(err);
    }
  };
  const postData = async () => {
    setLoading(true);
    await callApi();
    setLoading(false);
  };

  useEffect(() => {
    if (enabled) {
      postData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return { responseData, loading, error, refetch: callApi };
};

export default useCallApi;
