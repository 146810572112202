import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      message: "",
    };
  }

  componentDidCatch(error) {
    this.setState({
      isError: true,
      message: error.message ?? "An error occurred",
    });
  }
  render() {
    if (this.state.isError) {
      return <p>{this.state.message}</p>;
    }
    return <>{this.props.children}</>;
  }
}

export default ErrorBoundary;
