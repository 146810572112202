import { combineReducers } from "redux";
import AuthModalReducer from "./Auth";
import BuyerAuctionReducer from "./BuyerAuctionReducer";
import BuyerAuctionRetrieveReducer from "./BuyerAuctionRetrieveReducer";
import CarActualDataReducer from "./CarActualDataReducer";
import CarActualImagesReducer from "./CarActualImagesReducer";
import CreateConfigReducer from "./CreateConfigReducer";
import DealerReducer from "./DealerReducer";
import FilterReducer from "./FilterReducer";
import InventoryReducer from "./InventoyReducer";
import RegistrationReducer from "./RegistrationReducer";
import SideScrollReducer from "./SideScollReducer";
import StepsReducer from "./StepsReducer";
import StockImagesReducer from "./StockImagesReducer";
import ToastReducer from "./ToastReducer";
import YmmReducer from "./YmmReducer";

const rootReducer = combineReducers({
  StepsData: StepsReducer,
  Configuration: CreateConfigReducer,
  YmmCarsData: YmmReducer,
  StockImages: StockImagesReducer,
  InventoryCars: InventoryReducer,
  CarActualData: CarActualDataReducer,
  CarActualImages: CarActualImagesReducer,
  FilterData: FilterReducer,
  BuyerAuctionData: BuyerAuctionReducer,
  DealerReducerData: DealerReducer,
  BuyerAuctionRetrieveData: BuyerAuctionRetrieveReducer,
  RegistrationData: RegistrationReducer,
  toastReducer: ToastReducer,
  sideScrollData: SideScrollReducer,
  authModalReducer: AuthModalReducer,
});

export default rootReducer;
