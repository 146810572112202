import { DATA_TYPES, AUCTION_STATUS } from "@constants/";
import { callAPI } from "@utils/Api";

/**
 *
 * @param {*} data
 * @returns
 */
export const getAuctions = (data) =>
  callAPI(
    "v1/auction/list",
    "POST",
    data,
    undefined,
    window.ENV.REACT_APP_AUCTION_URL
  );
/**
 *
 * @param {*} param0
 * @returns
 */
export const placeBid = ({ auctionId, bidAmount, vin }) =>
  callAPI(
    `v1/auction/floor`,
    "POST",
    {
      index: "east",
      auction_id: auctionId,
      proxy_amount: Number(bidAmount),
      vin,
    },
    undefined,
    window.ENV.REACT_APP_AUCTION_URL
  );
/**
 *
 * @param {string} id
 * @returns
 */
export const getAuction = (id) =>
  callAPI("data/retrieve", "POST", {
    type: DATA_TYPES.AUCTION,
    id,
  });

export const updateAuction = ({ id, data }) =>
  callAPI("data/update_attributes", "POST", {
    type: DATA_TYPES.AUCTION,
    id,
    attributes: [data],
  });

export const update = ({ id, index, data }) =>
  callAPI("data/update", "POST", { id, index, ...data });

export const getAuctionsByBuyerId = (buyerId) =>
  callAPI("data/list", "POST", {
    size: 4,
    type: DATA_TYPES.AUCTION,
    query: {
      bool: {
        must: [
          { term: { type: DATA_TYPES.AUCTION } },
          { term: { "payload.buyer.id": buyerId } },
          { term: { status: AUCTION_STATUS.ACTIVE } },
        ],
      },
    },
    sort: [{ created: { order: "desc", unmapped_type: "string" } }],
  });
/**
 *
 * @param {*} param0
 * @returns
 */
export const createAuction = ({ configurationId, jacketId, userId }) =>
  callAPI(
    "v1/auction/create",
    "POST",
    {
      type: DATA_TYPES.AUCTION,
      vehicle_configuration_id: configurationId,
      parent_id: userId,
      jacket_id: jacketId,
      parent_type: DATA_TYPES.BUYER,
    },
    undefined,
    window.ENV.REACT_APP_AUCTION_URL
  );
