import Button from "@components/Button";
import Loader from "@components/Loader";
import LocationPopUp from "@components/LocationPopUp";
import ThemeInput from "@components/ThemeInput";
import { MOBILE_SCREEN_WIDTH } from "@constants/";
import useCallApi from "@hooks/useCallApi";
import useWindowDimentions from "@hooks/useWindowDimensions";
import { createConfiguration } from "@redux/Actions/CreateConfigActions";
import {
  eraseSelected,
  fillMakeData,
  fillModelData,
  fillSeriesData,
  fillStyleData,
  getYearCars,
  initialMake,
  initialModel,
  initialSeries,
  selectMake,
  selectModel,
  selectSeries,
  selectStyle,
  selectYear,
} from "@redux/Actions/StepsActions";
import { ymmInitial } from "@redux/Actions/YmmAction";
import classNames from "classnames";
import { get, snakeCase } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styles from "./styles.module.scss";

const YmmSelection = ({
  className,
  showLocationPopup = true,
  showResultsButton = true,
  showStyle = true,
  onButtonClick,
  buttonText = "",
  showStartHereText = false,
  menuPlacement = "auto",
  rootClass,
}) => {
  const [isLocationModalOpen, setLocationModalOpen] = useState(false);

  //steps for buy a vehicle..........................................
  const dispatch = useDispatch();
  const router = useHistory();

  const { width } = useWindowDimentions();
  const isMobile = width <= MOBILE_SCREEN_WIDTH;

  const { selectedData, carsData } = useSelector((state) => state.StepsData);
  const { isLoading } = useSelector((state) => state.Configuration);
  // Selectbox data
  const {
    year: backupYear,
    make: backupMake,
    model: backupModel,
    series: backupSeries,
    style: backupStyle,
  } = selectedData;

  const selectBoxMake = useMemo(() => {
    const filterMake = (carsData.data || []).filter(
      (item) => item.name === backupYear
    );
    return get(filterMake, "0.make_list", []);
  }, [carsData?.data, backupYear]);

  const selectBoxModel = useMemo(() => {
    const filterModel = selectBoxMake.filter(
      (item) => item.name === backupMake
    );
    return get(filterModel, "0.model_list", []);
  }, [selectBoxMake, backupMake]);

  const selectBoxSeries = useMemo(() => {
    const filterSeries = selectBoxModel.filter(
      (item) => item.name === backupModel
    );
    return get(filterSeries, "0.series_list", []);
  }, [selectBoxModel, backupModel]);

  const selectBoxStyle = useMemo(() => {
    const filterStyle = selectBoxSeries.filter(
      (item) =>
        backupSeries &&
        item.name === (backupSeries === "blank" ? "" : backupSeries)
    );
    return get(filterStyle[0], "style_list", []);
  }, [selectBoxSeries, backupSeries]);

  const getYears = () => {
    let yearsList = [];
    const currentYear = new Date().getFullYear();
    yearsList = [currentYear + 1, currentYear];

    for (let i = 1; i < 10; i++) {
      let prevYear = currentYear - i;
      yearsList = [...yearsList, prevYear];
    }
    return yearsList;
  };

  let { responseData, error, loading } = useCallApi("/vehicle/year", {}, "GET");

  const yearsData = useMemo(() => {
    if (error || responseData?.length === 0) {
      return getYears();
    }
    return responseData || [];
  }, [error, responseData]);

  //fill make dropdown data
  useEffect(
    function setValueFromStoreOnYearChange() {
      const { year, make } = selectedData;

      if (year && selectBoxMake.length) {
        dispatch(fillMakeData(selectBoxMake));
        dispatch(selectMake(""));
        const preSelectedMake = selectBoxMake.find(
          ({ name: makeName }) => makeName === make
        );

        if (preSelectedMake) {
          dispatch(selectMake(preSelectedMake.name));
        } else {
          dispatch(initialMake());
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectBoxMake]
  );

  //fill model dropdown
  useEffect(
    function setValueFromStoreOnMakeChange() {
      const { make } = selectedData;

      if (make && selectBoxModel.length) {
        dispatch(fillModelData(selectBoxModel));
        dispatch(selectModel(""));
        const { model } = selectedData;
        const preSelectedModel = selectBoxModel.find(
          ({ name: modelName }) => modelName === model
        );

        if (preSelectedModel) {
          dispatch(selectModel(preSelectedModel.name));
        } else {
          dispatch(initialModel());
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectBoxMake]
  );

  //fill series dropdown
  useEffect(() => {
    const { model, series } = selectedData;

    if (selectBoxModel.length && model) {
      const filterSeries = selectBoxModel.filter((item) => item.name === model);
      const seriesList = get(filterSeries, "0.series_list", []);
      dispatch(fillSeriesData(seriesList));
      dispatch(selectSeries(""));
      const preSelectedSeries = seriesList.find(
        ({ name: seriesName }) =>
          seriesName === (series === "blank" ? "" : series)
      );
      if (preSelectedSeries) {
        dispatch(selectSeries(preSelectedSeries.name || "blank"));
      } else {
        dispatch(initialSeries(""));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBoxModel]);

  //if only one style found then bydefault it will be selected
  const selectDefaultStyle = (styleData) => {
    if (styleData.length > 0) {
      dispatch(selectStyle(styleData[0]));
    }
  };

  //fill style dropdown
  useEffect(() => {
    const { series, style } = selectedData;

    if (selectBoxSeries.length && series) {
      if (series) {
        const filterStyle = selectBoxSeries.filter(
          (item) => item.name === (series === "blank" ? "" : series)
        );
        const styleList = get(filterStyle[0], "style_list", []);
        const selectedStyle = styleList.find(
          ({ name }) =>
            name.toLowerCase() === style?.name?.toLowerCase?.() ||
            name.toLowerCase() === style?.toLowerCase?.()
        );
        dispatch(fillStyleData(styleList));
        if (!selectedStyle) {
          selectDefaultStyle(styleList);
        } else {
          dispatch(selectStyle(selectedStyle));
        }
      } else {
        const styleList = get(selectBoxSeries[0], "style_list", []);
        dispatch(fillStyleData(styleList));
        selectDefaultStyle(styleList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectBoxSeries, selectedData.series]);

  const SwitchTypes = {
    YEAR: "Year",
    MAKE: "Make",
    MODEL: "Model",
    SERIES: "Series",
    DEFAULT: "Default",
  };

  const switchNameNotToValue = {
    [SwitchTypes.YEAR]: async (value) => {
      // dispatch(eraseSelected());
      await dispatch(getYearCars(value));
      dispatch(selectYear(value));
    },
    [SwitchTypes.MAKE]: (value) => {
      // dispatch(initialMake());
      dispatch(selectMake(value));
    },
    [SwitchTypes.MODEL]: (value) => {
      // dispatch(initialModel());
      dispatch(selectModel(value));
    },
    [SwitchTypes.SERIES]: (value) => {
      // dispatch(initialSeries());
      dispatch(selectSeries(value));
    },
    [SwitchTypes.DEFAULT]: (value) => {
      if (value) {
        const selectedStyle = selectBoxStyle?.find(
          (item) => item.name.toLowerCase() === value.toLowerCase()
        );
        if (selectedStyle) dispatch(selectStyle(selectedStyle));
      }
    },
  };

  const switchNameToValue = {
    [SwitchTypes.MAKE]: () => dispatch(initialMake()),
    [SwitchTypes.YEAR]: () => dispatch(eraseSelected()),
    [SwitchTypes.MODEL]: () => dispatch(initialModel()),
    [SwitchTypes.SERIES]: () => dispatch(initialSeries()),
    [SwitchTypes.DEFAULT]: () => dispatch(selectStyle("")),
  };

  //Handle the select box when value changes
  const handleSelect = async ({ target: { value, name } }) => {
    if (name === "Year" || name === "Make" || name === "Model") {
      dispatch(ymmInitial());
    }

    value !== name
      ? (
          switchNameNotToValue[name] ||
          switchNameNotToValue[SwitchTypes.DEFAULT]
        )(value)
      : (switchNameToValue[name] || switchNameToValue[SwitchTypes.DEFAULT])();
  };

  const toggleLocationPopUp = () => setLocationModalOpen(!isLocationModalOpen);

  const goToPage = (configId) => {
    toggleLocationPopUp();
    router.push(`/research/vehicle-inventory/${configId}`);
  };

  const onNext = ({ distance, zipcode }) => {
    localStorage.setItem("zipcode", zipcode);
    localStorage.setItem("distance", distance);
    dispatch(createConfiguration(distance, zipcode, goToPage));
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <div
        className={classNames(
          "car-selection-area w-100 d-flex align-items-center",
          rootClass
        )}
      >
        {showStartHereText && (
          <h2 className="text-white my-auto me-5">Start Here</h2>
        )}
        <div
          className={classNames(
            "mt-3 d-flex",
            styles.parentThemeInput,
            styles[className]
          )}
        >
          <div className={styles.otherInput}>
            <ThemeInput
              menuPlacement={menuPlacement}
              onChange={handleSelect}
              type="select"
              options={yearsData.map((year) => ({
                value: year.toString(),
                label: year,
              }))}
              name="Year"
              placeholder="Year"
              isLoading={loading}
              isDisabled={false}
              value={backupYear}
              isSearchable={!isMobile}
            />
          </div>
          <div className={styles.otherInput}>
            <ThemeInput
              menuPlacement={menuPlacement}
              name="Make"
              type="select"
              options={selectBoxMake.map((make) => ({
                value: make.name,
                label: make.name,
              }))}
              value={backupMake}
              placeholder="Make"
              onChange={handleSelect}
              isDisabled={selectBoxMake.length ? false : true}
              isSearchable={!isMobile}
            />
          </div>
          <div className={styles.otherInput}>
            <ThemeInput
              menuPlacement={menuPlacement}
              name="Model"
              type="select"
              options={selectBoxModel.map((model) => ({
                value: model.name,
                label: model.name,
              }))}
              value={backupModel}
              placeholder="Model"
              onChange={handleSelect}
              isDisabled={selectBoxModel.length <= 0}
              isSearchable={!isMobile}
            />
          </div>
          <div className={styles.otherInput}>
            <ThemeInput
              menuPlacement={menuPlacement}
              name="Series"
              type="select"
              options={selectBoxSeries.map((series) => ({
                value: series.name || "blank",
                label: series.name || "Base",
              }))}
              placeholder="Series"
              onChange={handleSelect}
              isDisabled={selectBoxSeries.length ? false : true}
              isSearchable={!isMobile}
              value={backupSeries}
            />
          </div>
          {showStyle && (
            <div className={styles.otherInput}>
              <ThemeInput
                menuPlacement={menuPlacement}
                name="style"
                type="select"
                options={selectBoxStyle.map((style) => ({
                  value: style.name,
                  label: style.name,
                }))}
                placeholder="Style"
                onChange={handleSelect}
                isDisabled={selectBoxStyle.length ? false : true}
                isSearchable={!isMobile}
                value={get(backupStyle, "name", null)}
              />
            </div>
          )}

          {showResultsButton && !buttonText ? (
            <Button
              type="button"
              variant={showStartHereText ? "secondary" : "primary"}
              size="sm"
              className={classNames(styles.updateBtn, {
                disabled: !selectedData.style,
                "px-4": true,
              })}
              onClick={
                showLocationPopup
                  ? toggleLocationPopUp
                  : () =>
                      onNext({
                        zipcode: localStorage.getItem("zipcode") || "",
                        distance:
                          parseInt(localStorage.getItem("distance")) || 200,
                      })
              }
              disabled={!selectedData.style}
              id="getResults_"
            >
              Get Results
            </Button>
          ) : (
            <Button
              size="sm"
              className={styles.updateBtn}
              onClick={() => onButtonClick(selectedData)}
              id={snakeCase(buttonText)}
            >
              <span>{buttonText}</span>
            </Button>
          )}
        </div>
      </div>

      {showLocationPopup && (
        <LocationPopUp
          open={isLocationModalOpen}
          toggle={toggleLocationPopUp}
          onNext={onNext}
          initialLocationValues={{
            zipcode: localStorage.getItem("zipcode") || "",
            distance: parseInt(localStorage.getItem("distance")) || 200,
          }}
        />
      )}
    </>
  );
};
export default YmmSelection;
