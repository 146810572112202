import { VehicleNotFound } from "@assets/Images";
import { getCarImage } from "@services/images";
import { preloadImage } from "@utils/image";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const useImage = (url) => {
  const [imageUrl, setImageUrl] = useState(VehicleNotFound);

  useEffect(() => {
    if (url !== "undefined?size=medium") {
      preloadImage(url, (e) => {
        if (!e) {
          setImageUrl(url);
        }
      });
    }
  }, [url]);

  return imageUrl;
};

export const useCarImage = (vin, options = {}) =>
  useQuery(["image", { vin }], () => getCarImage(vin), {
    enabled: options.enabled && Boolean(vin),
    ...options,
  });
