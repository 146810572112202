import {
  getIncentivePayment,
  getVendorPaymentQuote,
  getVendorPayments,
  getVendorVehicleDetails,
} from "@services/payment";
import { useQuery } from "react-query";

export const useIncentivePayment = (data, options) =>
  useQuery(
    ["incentive-details", data],
    () => getIncentivePayment(data),
    options
  );

export const useVendorPayments = (data, options) =>
  useQuery(
    ["vendor-payments", { ...data }],
    () => getVendorPayments(data),
    options
  );

export const useVendorPaymentQuote = (data, options) =>
  useQuery(
    ["vendor-payments-quote", { ...data }],
    () => getVendorPaymentQuote(data),
    options
  );

export const useVendorVehicleDetails = (data, options) =>
  useQuery(
    ["vendor-vehicle-details", { ...data }],
    () => getVendorVehicleDetails(data),
    options
  );
