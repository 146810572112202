import Button from "@components/Button";
import ThemeInput from "@components/ThemeInput";
import { DEFAULT_ERROR_MESSAGE } from "@constants";
import { SHOULD_SHOW_SIGNUP } from "@constants/";
import { useLogin } from "@hooks/auth";
import { useUpdateUserMutation } from "@hooks/user";
import { SHOW_TOAST } from "@redux/Actions/ToastActions";
import { LoginValidation } from "@utils/validations/Auth";
import { isYupError, parseYupError } from "@utils/Yup";
import Cookies from "js-cookie";
import { get, pick } from "lodash";
import QueryString from "qs";
import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { Col, Row } from "reactstrap";
import { toggleAuthModal } from "src/redux/Actions/Auth";
import { UserDetailsContext } from "src/Routes/AuthenticatedRoute";
import styles from "../styles.module.scss";

const RenderForm = () => {
  const onSuccess = useSelector((state) => state.authModalReducer.onSuccess);

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const { setUser } = useContext(UserDetailsContext);
  const { isLoading, mutateAsync: loginMutation } = useLogin();
  const { isLoading: savingUserEnvironment, mutateAsync: updateUserMutation } =
    useUpdateUserMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const signIn = async (e) => {
    e.preventDefault();
    try {
      await LoginValidation.validate(form, {
        abortEarly: false,
      });
      const {
        token,
        expires,
        user: userDetails,
      } = await loginMutation({ ...form, type: "buyer" });
      Cookies.set("loginToken", token);
      Cookies.set("tokenExpiration", expires);
      const user = get(userDetails, "_source");
      setUser({ ...user, id: userDetails._id });

      try {
        const environment = pick(window.navigator, [
          "appCodeName",
          "appName",
          "appVersion",
          "language",
          "languages",
          "pdfViewerEnabled",
          "permissions",
          "platform",
          "plugins",
          "presentation",
          "product",
          "productSub",
          "userActivation",
          "userAgent",
          "userAgentData",
          "vendor",
        ]);

        await updateUserMutation({
          id: userDetails._id,
          data: { payload: { environment } },
        });
      } catch (error) {
        const message = get(
          error,
          "response.data.message",
          DEFAULT_ERROR_MESSAGE
        );
        dispatch({
          type: SHOW_TOAST,
          payload: {
            type: "error",
            message,
          },
        });
      }

      if (onSuccess) {
        onSuccess(userDetails._id);
      } else {
        const path =
          QueryString.parse(location.search.replace("?", "")).redirectUrl ||
          "/";
        history.push(path);
      }
      dispatch(toggleAuthModal(""));
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        const message = get(
          error,
          "response.data.message",
          get(error, "response.data.error.message", DEFAULT_ERROR_MESSAGE)
        );
        dispatch({
          type: SHOW_TOAST,
          payload: {
            type: "error",
            message,
          },
        });
      }
    }
  };

  return (
    <>
      <form onSubmit={signIn} className={styles.form}>
        <Row className="mb-4">
          <Col lg={12} sm={12}>
            <ThemeInput
              type="text"
              name="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Email Address"
              label="Email Address"
              showRequiredMark
              error={errors.email}
            />
            <ThemeInput
              type="password"
              name="password"
              value={form.name}
              onChange={handleChange}
              placeholder="Password"
              label="Password"
              showRequiredMark
              error={errors.password}
            />

            <Button
              className="my-4"
              type="submit"
              id="login_"
              isLoading={isLoading || savingUserEnvironment}
              size="sm"
              variant="primary"
              block
            >
              LOGIN
            </Button>
            <div className={styles.showLine}>
              {SHOULD_SHOW_SIGNUP() && (
                <p className={styles.paraDesc1}>
                  Don't have an account?{" "}
                  <span
                    className={styles.textBlue}
                    id="signUpRedirect_"
                    onClick={() =>
                      dispatch(toggleAuthModal("signup", onSuccess))
                    }
                  >
                    Sign Up
                  </span>
                </p>
              )}

              <p
                className={styles.textBlue}
                id="forgetPasswordRedirect_"
                onClick={() =>
                  dispatch(toggleAuthModal("forgotPassword", onSuccess))
                }
              >
                Forgot Password
              </p>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default RenderForm;
