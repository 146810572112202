import classNames from "classnames";
import React from "react";
import StepItem from "./StepItem";
import styles from "./styles.module.scss";

const SideStepper = ({ title, extra, items = [] }) => {
  return (
    <div className={styles.sidePanelNav}>
      <div
        className={classNames(
          "py-3",
          "px-2",
          "d-flex",
          styles.sidePanelHeader,
          "align-items-center",
          "justify-content-between"
        )}
      >
        <h5 className="text-white ms-4 ms-md-5 ms-lg-0 my-3 ps-3">{title}</h5>
        {extra}
      </div>
      <ul className={styles.listGroup}>
        {items.map(
          (
            {
              isEnabled,
              isActive,
              imageVin,
              showIndicator,
              handleClick,
              isCompleted,
              title,
              subtitle,
            },
            index
          ) => (
            <StepItem
              id={index}
              title={title}
              subtitle={subtitle}
              key={index}
              isEnabled={isEnabled}
              isActive={isActive}
              imageVin={imageVin}
              showIndicator={showIndicator}
              handleClick={handleClick}
              isCompleted={isCompleted}
            />
          )
        )}
      </ul>
    </div>
  );
};

export default SideStepper;
