import { SET_ACTIVE_PAGE, STEP_VISITED } from "./ActionTypes";

export const setStepVisited = (data) => (dispatch) =>
  dispatch({
    type: STEP_VISITED,
    payload: data,
  });

export const setActivePage = (data) => (dispatch) =>
  dispatch({
    type: SET_ACTIVE_PAGE,
    payload: data,
  });
